import {
   Indicator,
   IndicatorWrapper,
   Message,
   Root,
   Wrapper,
} from "./LoadingIndicator.css";
const LoadingIndicator = ({ message }: loadingIndicatorProps) => {
   return (
      <Root>
         {message && <Message children={message} />}
         <Wrapper>
            <IndicatorWrapper>
               <Indicator />
            </IndicatorWrapper>
         </Wrapper>
      </Root>
   );
};
export default LoadingIndicator;

type loadingIndicatorProps = {
   message?: string;
};
