import ReCAPTCHA from "react-google-recaptcha";
import { ErrorMessage, Form, Formik, Field, FormikHelpers, FieldProps } from "formik";
import { useTranslation } from "react-i18next"; // Importujemy funkcję useTranslation do obsługi tłumaczeń

import {
   ErrorWrapper,
   InputWrapper,
   InputsWrapper,
   Label,
   Paragraph,
   Root,
   SubmitButton,
} from "./NewsletterForm.css";
import { postStatusT } from "components/Newsletter/Newsletter";

const reCaptchaKey = "6Lfh_lwgAAAAAOm0_y1_3pkzKsmj3AmlRdYlAsFt";

export default function NewsletterForm({
   postStatus,
   setPostStatus,
}: newsletterFormProps) {
   const { t } = useTranslation(); // Inicjalizacja hooka do tłumaczeń

   if (postStatus !== "none") return <></>;
   const initialValues = {
      name: "",
      email: "",
      agreement: false,
      recaptcha: false,
   };
   const handleSubmit = (
      values: newsletterFormValues,
      action: FormikHelpers<newsletterFormValues>
   ) => {
      setPostStatus("pending");
      const body = JSON.stringify({ name: values.name, email: values.email });
      fetch("/api/newsletter", {
         method: "POST",
         body,
         headers: { "Content-Type": "application/json" },
      })
         .then((res) => {
            if (res.ok) return res.json();
            throw new Error(res.statusText);
         })
         .then((res) => {
            const response = res as freshMailResponse;
            if (response.code === 1304) return setPostStatus("email exist");
            if (response.code === 200) return setPostStatus("success");
            setPostStatus("error");
         })
         .catch((err) => {
            setPostStatus("error");
            console.error = err;
         });
   };
   const validate = (values: newsletterFormValues) => {
      const { email, name, agreement, recaptcha } = values;
      const errors: errorsType = {};
      //email
      if (!email) errors.email = t("newsletterForm.emailRequired");
      if (
         email !== "" &&
         email !== undefined &&
         !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
      )
         errors.email = t("newsletterForm.invalidEmail");
      //name
      if (!name) errors.name = t("newsletterForm.nameRequired");
      //agreement
      if (!agreement)
         errors.agreement = t("newsletterForm.agreementRequired");
      ///recaptcha
      if (!recaptcha) errors.agreement = t("newsletterForm.recaptchaRequired");
      return errors;
   };
   return (
      <Root>
         <Formik
            onSubmit={(values, action) => handleSubmit(values, action)}
            initialValues={initialValues}
            validate={validate}
         >
            {({ values, isValid, touched, setValues, errors }) => {
               const isDisabled = !isValid || Object.keys(touched).length === 0;
               return (
                  <Form>
                     <InputsWrapper>
                        <InputWrapper>
                           {" "}
                           <Field id="name" name="name" placeholder={t("newsletterForm.namePlaceholder")} />
                           <ErrorWrapper
                              isDisplayed={
                                 touched.name !== undefined &&
                                 errors.name !== undefined
                              }
                           >
                              <ErrorMessage name="name" />
                           </ErrorWrapper>
                        </InputWrapper>

                        <InputWrapper>
                           {" "}
                           <Field id="email" name="email">
                              {({
                                 field,
                                 form: { touched, errors },
                              }: FieldProps) => (
                                 <>
                                    <input
                                       placeholder={t("newsletterForm.emailPlaceholder")}
                                       type="text"
                                       autoComplete="off"
                                       autoCorrect="off"
                                       autoCapitalize="off"
                                       spellCheck="false"
                                       {...field}
                                    />
                                    <ErrorWrapper
                                       isDisplayed={
                                          touched.email !== undefined &&
                                          errors.email !== undefined
                                       }
                                    >
                                       {" "}
                                       <ErrorMessage name="email" />
                                    </ErrorWrapper>
                                 </>
                              )}
                           </Field>
                        </InputWrapper>
                     </InputsWrapper>
                     <InputWrapper>
                        <Label htmlFor="agreement">
                           <Field
                              type="checkbox"
                              id="agreement"
                              name="agreement"
                           />
                           <Paragraph>{t("newsletterForm.agreementText")}</Paragraph>
                        </Label>
                        <ErrorWrapper
                           isDisplayed={
                              touched.agreement !== undefined &&
                              errors.agreement !== undefined
                           }
                        >
                           <ErrorMessage name="agreement" />
                        </ErrorWrapper>
                     </InputWrapper>
                     <InputWrapper>
                        <ReCAPTCHA
                           sitekey={reCaptchaKey}
                           hl="pl"
                           onChange={(e) =>
                              setValues({ ...values, recaptcha: true })
                           }
                        />
                        <ErrorWrapper
                           isDisplayed={
                              touched.recaptcha !== undefined &&
                              errors.recaptcha !== undefined
                           }
                        >
                           <ErrorMessage name="recaptcha" />
                        </ErrorWrapper>
                     </InputWrapper>

                     <SubmitButton
                        type="submit"
                        children={t("newsletterForm.submitButton")}
                        disabled={isDisabled}
                     />
                  </Form>
               );
            }}
         </Formik>
      </Root>
   );
}

interface newsletterFormValues {
   name: string;
   email: string;
   agreement: boolean;
   recaptcha: boolean;
}

interface freshMailResponse {
   status: "OK" | "ERROR";
   code?: number;
}

interface newsletterFormProps {
   postStatus: postStatusT;
   setPostStatus: React.Dispatch<React.SetStateAction<postStatusT>>;
}
interface errorsType {
   name?: string;
   email?: string;
   agreement?: string;
   recaptcha?: string;
}
