import { Title, Paragraph, Root } from "./NewsletterPostMessage.css";
import { postStatusT } from "components/Newsletter/Newsletter";
import { useTranslation } from "react-i18next"; // Importujemy funkcję useTranslation do obsługi tłumaczeń

export default function NewsletterPostMessage({
   postStatus,
}: newsletterPostMessageProps) {
   const { t } = useTranslation(); // Inicjalizacja hooka do tłumaczeń

   if (postStatus === "none" || postStatus === "pending") return <></>;
   const renderTitle = () => {
      switch (postStatus) {
         case "success":
            return t("newsletterPostMessage.successTitle");
         case "error":
            return t("newsletterPostMessage.errorTitle");
         case "email exist":
            return t("newsletterPostMessage.emailExistTitle");
      }
   };
   const renderParagraph = () => {
      switch (postStatus) {
         case "success":
            return t("newsletterPostMessage.successParagraph");
         case "error":
            return t("newsletterPostMessage.errorParagraph");
         case "email exist":
            return t("newsletterPostMessage.emailExistParagraph");
      }
   };
   return (
      <Root postStatus={postStatus}>
         <Title children={renderTitle()} />
         <Paragraph children={renderParagraph()} />
      </Root>
   );
}

interface newsletterPostMessageProps {
   postStatus: postStatusT;
}
