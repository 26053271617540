import karolinaPortrait from "assets/img/portraits/karolina_biedron.webp";
import antoninaPortrait from "assets/img/portraits/antonina_federowicz.webp";
import katarzynaPortrait from "assets/img/portraits/katarzyna_kowinska.webp";


const aboutUsText = {
  header: {
    pl: "W naszej pracy najważniejsze są inkluzyjność, otwartość, antyschematyzm oraz dobro człowieka i środowiska. Dążymy do włączania w działania wszystkich osób, bez względu na płeć, pochodzenie, wygląd, stopień sprawności, wiek, orientację seksualną, czy inne cechy tożsamościowe. Odkrywamy nowe sposoby na rozwiązywanie zaobserwowanych przez nas problemów społecznych. Działamy kreatywnie, z poszanowaniem środowiska naturalnego. Dążymy do rozpowszechnienia wiedzy o prawach człowieka oraz ich przestrzegania.",
    en: "xxxxxxxxxx",
  },

  person: [
    {
      id: 0,
      name: "Antonina Federowicz",
      description: {
        pl: "Współzałożycielka Fundacji Po Prostu Da Się, gdzie koordynuje i realizuje projekty. Entuzjastka nowych technologii, które z powodzeniem wprowadza w fundacji (odpowiadała m.in. za wdrożenie innowacji w przedsiębiorstwie dofinansowanego w ramach Funduszy Europejskich). Działa jako analityczka, instruktorka zajęć artystycznych, włączających grupy zagrożone wykluczeniem. Współtworzy projekty edukacji teatralnej wśród dzieci, młodzieży i seniorów. Jest aktorką Teatru Miejskiego w Gliwicach, absolwentką Akademii Sztuk Teatralnych we Wrocławiu oraz Szkoły Głównej Handlowej w Warszawie. Dzieciństwo spędziła w teatrze co rozwinęło w niej otwartość, ciekawość i odwagę. Kultura jest dla niej najlepszą przestrzenią do poznania drugiego człowieka oraz doskonalenia siebie. Chciałaby, żeby była to przestrzeń w której każdy może znaleźć swoje miejsce.",
        en: "Co-founder of You Simply Can Foundation, where she coordinates and implements projects. A technology enthusiast who successfully introduces innovations within the foundation (she is responsible for implementing innovations in a company funded by European Funds). She works as an analyst, instructor of artistic activities that include marginalized groups. She creates theatrical education projects for children, youth, and seniors. She is an actress at the Municipal Theater in Gliwice, a graduate of the Academy of Theater Arts in Wrocław, and the Warsaw School of Economics. She spent her childhood in the theater, which developed her openness, curiosity, and courage. She perceives culture as the best space for understanding others and self-improvement. She would like it to be a space where everyone can find their place.",
      },
      photo: antoninaPortrait,
    },
    {
      id: 1,
      name: "Karolina Biedroń",
      description: {
        pl: "Współzałożycielka Fundacji Po Prostu Da Się, gdzie koordynuje i realizuje autorskie projekty. Jest absolwentką Akademii Sztuk Teatralnych we Wrocławiu. Zawodowo łączy teatr z działalnością społeczną i aktywizmem. Ukończyła roczny kurs z zakresu prowadzenia działań artystycznych z osobami należącymi do mniejszości - „Teatr - sztuka społeczna 2020”, “Akademię Praw Człowieka Humanity in Action 2021” i kurs edukacji antydyskryminacyjnej Towarzystwa Edukacji Antydyskryminacyjnej 2022. Organizuje i prowadzi warsztaty teatralne z grupami zagrożonymi wykluczeniem społecznym. W wolnym czasie biega po lesie i rozmawia z ludźmi jako trenerka outdoor w projekcie From Outdoors to Labour Market. Doświadczenie aktorskie zdobywała w Teatrze im. Stefana Batorego, grała też w spektaklach repertuarowych Teatru AST oraz w Centrum Twórczości Dziecka we Wrocławiu. Wcześniej pracowała m.in. jako instruktorka i wychowawczyni na obozach teatralnych, opiekunka artystyczna podczas zdjęć filmowych oraz animatorka.",
        en: "Co-founder of You Simply Can Foundation, where she coordinates and executes projects. She is a graduate of the Academy of Theatre Arts in Wrocław. Professionally, she combines theater with social work and activism. She completed a one-year course in conducting artistic activities with minority individuals - 'Theater - Social Art 2020,' 'Humanity in Action Human Rights Academy 2021,' and the anti-discrimination education course by the Anti-Discrimination Education Society in 2022. She organizes and leads theater workshops with socially marginalized groups. In her free time, she enjoys being in the forest and engaging in conversations with people as an outdoor trainer in the 'From Outdoors to Labour Market' project. She gained acting experience at the Stefan Batory Theatre, she performed in repertoire plays at the AST Theatre and the Children's Creative Center in Wrocław. Previously, she worked as an instructor and counselor at theater camps, artistic supervisor during film shoots, and animator.",
      },
      photo: karolinaPortrait,
    },
    {
      id: 2,
      name: "Katarzyna Kowińska",
      description: {
        pl: "W Fundacji Po Prostu Da Się od 2022 roku. Zajmuje się komunikacją i promocją, wspiera realizację projektów. Z wykształcenia kulturoznawczyni, zawodowo związana z lokalnymi mediami (pracowała jako dziennikarz prasowy i telewizyjny, a także prowadząca zajęcia dziennikarskie dla młodzieży w Stowarzyszeniu Animatorów Wszechstronnego Rozwoju Młodzieży). Realizuje nagrania lektorskie i prowadzi spotkania autorskie (na swoim koncie ma m.in. rozmowy z Mariuszem Szczygłem, Szczepanem Twardochem, Anną Kańtoch, Januszem L. Wiśniewskim, Wojciechem Chmielarzem, Jakubem Żulczykiem czy Arturem Andrusem). Działa w organizacjach pozarządowych (zawodowo i wolontariacko). Jako pracownik, a później kierownik Centrum Organizacji Kulturalnych w Gliwickim Centrum Organizacji Pozarządowych, organizowała wydarzenia kulturalne, prowadziła szkolenia z zakresu komunikacji zewnętrznej, kontaktów z mediami i kreatywnych działań dla młodzieży i dorosłych.",
        en: "At the You Simply Can Foundation since 2022, she handles communication and promotion while supporting project implementation. With a background in cultural studies, she has a professional history in local media, working as a print and television journalist and conducting journalism workshops for youth at the Association of Comprehensive Youth Development Animators. She also carries out voiceover recordings and hosts author meetings, including conversations with Mariusz Szczygieł, Szczepan Twardoch, Anna Kańtoch, Janusz L. Wiśniewski, Wojciech Chmielarz, Jakub Żulczyk, and Artur Andrusek, among others. She actively participates in non-governmental organizations both professionally and as a volunteer. While working and later serving as the head of the Center for Cultural Organizations at the Gliwice Center for Non-Governmental Organizations, she organized cultural events, conducted training in external communication, media relations, and creative activities for both youth and adults.",
      },
      photo: katarzynaPortrait,
    },
  ],
};

export default aboutUsText;
