import React from "react";
import { useTranslation } from "react-i18next";
import { FlagIcon, LanguageButton } from "./LanguageSwitcher.css";

type LanguageSwitcherProps = {
  selectedLanguage: string;
  onChangeLanguage: () => void;
};

function LanguageSwitcher({
  selectedLanguage,
  onChangeLanguage,
}: LanguageSwitcherProps) {
  const { i18n } = useTranslation();

  const handleClick = () => {
    // Jeśli obecny język to "pl", zmień na "en", a jeśli obecny język to "en", zmień na "pl"
    const newLanguage = selectedLanguage === "pl" ? "en" : "pl";
    onChangeLanguage();
    i18n.changeLanguage(newLanguage); // Ustaw nowy język
  };

  return (
    <LanguageButton onClick={handleClick}>
      {selectedLanguage === "pl" ? (
        <FlagIcon
          countryCode="GB"
          svg
          style={{
            borderRadius: "15px",
            width: "7em",
            height: "5em",
          }}
          title="English"
        />
      ) : (
        <FlagIcon
          countryCode="PL"
          svg
          style={{
            borderRadius: "15px",
            width: "7em",
            height: "5em",
          }}
          title="Polski"
        />
      )}
    </LanguageButton>
  );
}

export default LanguageSwitcher;
