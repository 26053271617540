import styled from "styled-components";

export const Root = styled.footer`
   position: relative;
   grid-area: footer;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-top: 10rem;
   justify-content: center;
   font-size: 4rem;
   color: ${({ theme: { colors } }) => colors.white};
   &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100vw;
      background: ${({ theme: { colors } }) => colors.darkGray};
      z-index: -1;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      padding-top: 5rem;
   }
`;
export const Grid = styled.div`
   display: grid;
   grid-template-columns: 2fr 1fr;
   grid-template-rows: repeat(2, auto);
   grid-template-areas: " description socialmedia" "creators  creators";
   justify-content: center;
   width: 100%;
   gap: 2rem;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      grid-template-areas: "description" "socialmedia" "creators";
   }
`;

export const Contact = styled.div`
   grid-area: description;
   display: flex;
   align-items: center;
   justify-content: space-around;
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      flex-direction: column;
   }
`;
export const ParagraphWrapper = styled.div``;
export const SocialMedia = styled.div`
   grid-area: socialmedia;
   display: flex;
   justify-content: flex-end;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      justify-content: center;
   }
`;

export const Creators = styled.p`
padding: 1rem;
grid-area: creators;
color: ${({theme:{colors}})=>colors.white};
font-size: 1.4rem;
text-align: center;
`;

export const Title = styled.h4`
   margin-bottom: 2rem;
`;

export const Paragraph = styled.p`
   font-size: 1.8rem;
   margin: 0.4rem 0;
   line-height: 1.6rem;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      text-align: center;
   }
`;
export const Logo = styled.img`
   max-width: 20rem;
   margin-bottom: 2rem;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      margin-bottom: 4rem;
   }
`;
export const Icon = styled.img`
   max-width: 3rem;
   filter: brightness(0) invert(1);
`;
export const Link = styled.a`
   font-size: 1.8rem;
   transition: ${({ theme: { transition } }) => transition};
   opacity: 0.7;
   font-weight: bold;
   &:hover {
      opacity: 1;
   }
   margin: 2rem;
   svg {
      font-size: 3rem;
   }
`;
