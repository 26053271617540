import { createGlobalStyle } from "styled-components";

import {
   fontSizeType,
   switchPayloadType,
} from "features/reducers/theme.reducer";

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`



*{
   font-family: 'Champagne&Limousines', sans-serif;
   font-size: ${({ fontSize }) => `${10 + fontSize}px`};
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   transition: color 0.1s, background-color 0.1s;

}
body {
   background-color: ${({ theme: { colors } }) => colors.white};
   color: ${({ theme: { colors } }) => colors.black};
   max-width: 100%;
   overflow-x:hidden;
  
   
}
a {
   text-decoration: none;
   color: inherit;
   &.active {
      pointer-events: none;
      button {
         color: ${({ theme: { colors } }) => colors.darkGray};
         transform:scale(1.2);
      }
   }
}
section, header {
   margin: 5rem 0;
}
h1, h2 {
   font-family: "GillSansUltraBold";
   letter-spacing: 3px;
   color: ${({ theme: { colors } }) => colors.darkGray}
}
h3,h4,h5,h6{
   font-family: 'Champagne&Limousines', sans-serif;
}
h1{
   font-size: 6rem;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      font-size: 5.5rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      font-size: 5rem;
   }
}
h2 {
   font-size: 5.8rem;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      font-size: 5rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      font-size: 4rem;
   }
}
h3 {
   font-size: 4.5rem;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      font-size: 4rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      font-size: 3rem;
   }
}
h4{
   font-size: 2.6rem;
}
//page animation
.fade-in-enter {
   opacity: 0;
 }
 .fade-in-enter-active {
   opacity: 1;
   transition: opacity 300ms;
 }
 .fade-in-exit {
   opacity: 1;
 }
 .fade-in-exit-active {
   opacity: 0;
   transition: opacity 300ms;
 }
 .fade-in-600-enter {
   opacity: 0;
 }
 .fade-in-600-enter-active {
   opacity: 1;
   transition: opacity 600ms;
 }
 .fade-in-600-exit {
   opacity: 1;
 }
 .fade-in-600-exit-active {
   opacity: 0;
   transition: opacity 600ms;
 }
 .slide-to-left-enter {
   transform: translateX(250%) !important;
 }
 .slide-to-left-enter-active {
   transform: translateX(-50%) !important;
   transition: transform 500ms !important;
 }
 .slide-to-left-exit {
   transform: translateX(-50%) !important;
 }
 .slide-to-left-exit-active {
   transform: translateX(-250%) !important;
   transition: transform 500ms !important;
 }
 .slide-to-right-enter {
   transform: translateX(-250%) !important;
 }
 .slide-to-right-enter-active {
   transform: translateX(-50%)!important;
   transition: transform 500ms!important;
 }
 .slide-to-right-exit {
   transform: translateX(-50%) !important;
 }
 .slide-to-right-exit-active {
   transform: translateX(250%) !important;
   transition: transform 500ms !important;
 }
 ///animation
 @keyframes bounce {
   0%   { transform: scale(1,1)    translateY(0); }
   5%  { transform: scale(1.1,.9) translateY(0); }
   15%  { transform: scale(.9,1.1) translateY(-20px); }
   25%  { transform: scale(1,1)    translateY(0); }
   28.5%  { transform: scale(1,1)    translateY(-7px); }
   32%  { transform: scale(1,1)    translateY(0); }
   100% { transform: scale(1,1)    translateY(0); }

}
////dark and dark yellow theme
 ${({ theme: { colors }, themeName }) =>
    themeName !== "light"
       ? `
       * {
       color: ${colors.black} !important;
       font-weight: bold !important;
       background-color: ${colors.white} !important;
       }

 button { 

    border: 1px solid ${colors.black} !important;
  
 }
 `
       : ""}
`;
type GlobalStyleProps = {
   themeName: switchPayloadType;
   fontSize: fontSizeType;
};
