import styled from "styled-components";

const Root = styled.section`
   grid-area: timeline;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: 4rem;
`;

const Title = styled.h2`
   margin-bottom: 4rem;
   text-align: center;
   text-transform: uppercase;

   @media (max-width: ${({ theme: { media } }) => media.xlg}) {
      font-size: 5rem;
      padding: 0 2rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      font-size: 3rem;
      letter-spacing: 0;
      padding: 1rem;
   }
`;

const TimeLine = styled.div`
   padding: 2rem;
   color: ${({ theme: { colors } }) => colors.white};
   font-size: 1.8rem;
   width: 100%;
   border-radius: 2rem;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      padding: 0.5rem;
   }
`;

const Button = styled.button`
   padding: 1.5rem 3.5rem;
   font-size: 2.8rem;
   font-weight: bold;
   background-color: ${({ theme: { colors } }) => colors.red};
   color: ${({ theme: { colors } }) => colors.white};
   border: none;
   border-radius: 1.2rem;
   cursor: pointer;
   transition: 0.2s;
   &.active {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
      cursor: auto;
      &:hover {
         transform: scale(1);
      }
   }
   &:hover {
      transform: scale(1.05);
   }
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      font-size: 2.3rem;
      padding: 1.5rem 2rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      margin: 0;
      &:hover {
         transform: scale(1);
      }
   }
`;
const ButtonsContainer = styled.div`
   width: 100%;
   display: flex;
   justify-content: flex-start;
   transform: translateY(2rem);
   .swiper {
      max-width: 100%;
      min-height: 80%;
      margin-left: 0;
   }

   .swiper-slide {
      width: auto;
      max-width: 100%;
   }
`;
const ButtonWrapper = styled.div`
   display: flex;
   margin-right: 3rem;
   align-items: start;
   height: 10rem;
   border-radius: 2rem 2rem 0 0;
   transition: 0.2s;

   &.active {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
   }

   @media (max-width: ${({ theme: { media } }) => media.md}) {
      margin-right: 1rem;
      height: 8rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      justify-content: center;
      height: 10rem;
      margin-right: 0.5rem;
   }
`;

const ContentWrapper = styled.div`
   display: flex;
   position: relative;
   width: 100%;
   height: 60rem;
   align-items: center;
   justify-content: space-around;
   padding: ${({ theme: { spacing } }) => spacing.xlg};
   background-color: ${({ theme: { colors } }) => colors.darkGray};
   border-radius: 2rem;
   overflow: hidden;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      height: unset;
      min-height: 60rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      padding: 0;
   }
`;
const CardsWrapper = styled.div`
   position: absolute;
   display: flex;
   align-items: center;
   justify-content: space-around;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   width: 100%;
   height: 100%;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      flex-direction: column;
      min-height: 100%;
   }
   .swiper {
      max-width: 95%;
      min-height: 80%;
   }

   .swiper-slide {
      width: auto;
      max-width: 100%;
   }
   .swiper-button-disabled {
     opacity: 0;
     pointer-events: auto;
   }
   .swiper-button-next,
   .swiper-button-prev {
      color: ${({ theme: { colors } }) => colors.blue};
   }
   .swiper-button-next {
      right: 0;
   }
   .swiper-button-prev {
      left: 0;
   }
   .swiper-pagination {
      bottom: 0;
   }
   .swiper-pagination-bullet-active {
      background-color: ${({ theme: { colors } }) => colors.blue};
   }
`;
export {
   Button,
   ButtonWrapper,
   ButtonsContainer,
   CardsWrapper,
   ContentWrapper,
   Root,
   Title,
   TimeLine,
};
