import styled from "styled-components";

export const Root = styled.section`
   grid-area: slider;
   .swiper-wrapper {
      align-items: center;
   }
   .swiper-button-next,
   .swiper-button-prev {
      color: ${({ theme: { colors } }) => colors.red};
      @media (max-width: ${({ theme: { media } }) => media.md}) {
         display: none;
      }
   }
   .swiper-button-next {
      right: 0;
   }
   .swiper-button-prev {
      left: 0;
   }
   .swiper-pagination-bullet-active {
      background-color: ${({ theme: { colors } }) => colors.red};
   }
   .swiper-pagination {
      bottom: 0;
   }
   .swiper {
      padding: 2rem;
   }
`;

export const Title = styled.h2`
   width: 100%;
   text-align: center;
   text-transform: uppercase;
   margin: 4rem 0 10rem;
   @media (max-width: ${({ theme: { media } }) => media.xlg}) {
      font-size: 5rem;
      padding: 0 2rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      font-size: 4rem;
      padding: 0 1rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      font-size: 3rem;
      padding: 0 1rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      font-size: 2.4rem;
      letter-spacing: 0;
      padding: 1rem;
   }
`;

export const Logo = styled.img`
   max-width: 30rem;
   max-height: 30rem;

   @media (max-width: ${({ theme: { media } }) => media.md}) {
      max-width: 20rem;
      max-height: 20rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      max-width: 16rem;
      max-height: 16rem;
   }
`;
