import styled from "styled-components";

export const Root = styled.section`
   grid-area: actions;
   display: flex;
   justify-content: space-around;
   align-items: start;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }
`;
export const Action = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      flex-direction: row;
      margin: 2rem;
      justify-content: center;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      width: 100%;
      justify-content: space-around;
   }
`;

export const ActionImage = styled.img`
   width: 20rem;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      width: 15rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      width: 12rem;
   }
`;

export const Button = styled.button`
   margin-top: 4rem;
   padding: 1.4rem 1rem;
   width: 18rem;
   color: ${({ theme: { colors } }) => colors.white};
   background-color: ${({ theme: { colors } }) => colors.red};
   border: none;
   border-radius: 0.5rem;
   text-transform: uppercase;
   text-align: center;
   font-size: 3rem;
   font-weight: normal;
   cursor: pointer;
   transition: ${({ theme: { transition } }) => transition};
   &:hover {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      margin: 1rem 3rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      margin: 1rem;
   }
`;

export const ButtonTitle = styled.strong`
   font-size: 2.3rem;
   width: 100%;
   text-align: center;
`;
export const ButtonParagraph = styled.p`
   padding: 0.2rem;
   text-align: left;
   font-size: 1.8rem;
   text-transform: none;
`;
