import styled from "styled-components";

const Root = styled.div`
   margin: 0 2rem;
   flex-basis: 33%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: start;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      margin: 2rem 0;
   }
`;

const Portrait = styled.img`
   margin-top: 4rem;
   height: 25rem;
   width: 25rem;
   border-radius: 50%;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      height: 15rem;
      width: 15rem;
   }
`;
const PersonName = styled.h3`
   margin: 4rem 0;
   font-size: 2.6rem;
   text-transform: uppercase;
   text-align: center;
`;
const PersonText = styled.p<personTextProps>`
   font-size: 2rem;
   line-height: 2.4rem;
   text-align: justify;
   padding: 3.5rem 3rem;
   color: ${({ theme: { colors } }) => colors.darkGray};
   background-color: ${({ theme: { colors } }) => colors.white};
   border-radius: 2rem;
   flex-grow: 1;
   max-height: 500rem;
   transition: 0.7s;
   &.first {
      line-height: 2.8rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      font-size: 1.8rem;
      max-height: ${({ isTrimed }) => (isTrimed ? "25rem" : "500rem")};
      overflow: hidden;
      position: relative;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      font-size: 2.1rem;
      width: 90%;
      line-height: 2.8rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      font-size: 1.8rem;
      line-height: 2.5rem;
      width: 100%;
      padding: 4.5rem 2rem;
   }
`;
const Button = styled.button`
   display: none;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      display: block;
      margin: 1rem auto;
      padding: 1.2rem 2rem;
      position: absolute;
      bottom: 0rem;
      right: 0;
      font-weight: bold;
      background-color: ${({ theme: { colors } }) => colors.red};
      color: ${({ theme: { colors } }) => colors.white};
      border: none;
      border-radius: 1rem;
      transform: translateX(-50%);
      z-index: 1;
      &::after {
         content: "";
         position: absolute;
         top: -50%;
         left: 50%;
         width: 800rem;
         height: 200%;
         background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.6) 40%,
            rgba(255, 255, 255, 1) 60%,
            rgba(255, 255, 255, 1) 100%
         );
         transform: translateX(-50%);
         z-index: -2;
      }
      &::before {
         content: "";
         position: absolute;
         top: 0;
         left: 50%;
         width: 100%;
         height: 100%;
         background-color: ${({ theme: { colors } }) => colors.red};
         transform: translateX(-50%);
         z-index: -1;
         border-radius: 1rem;
      }
      svg {
         font-size: 1.5rem;
         margin-left: 0.5rem;
      }
   }
`;
type personTextProps = {
   isTrimed: boolean;
};
export { Button, Root, Portrait, PersonName, PersonText };
