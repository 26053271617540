import styled from "styled-components";

export const Grid = styled.div<gridProps>`
   margin: 0 auto; 
   display: grid;
   max-width: 120rem;
   grid-template-columns: 1fr;
   grid-template-rows: ${({ navOffset }) => navOffset}px auto auto auto;
   grid-template-areas: "navbar" "header" "content" "footer";
   @media (max-width: ${({ theme: { media } }) => media.lg}) {
      max-width: 100vw;
   }
   @media(max-width:${({theme:{media}})=>media.md}){
      margin-top: 6.5rem;
      grid-template-rows: auto auto auto auto;
   }
`;

type gridProps = { navOffset?: number, };