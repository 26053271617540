import { Root } from "./Grid.css";
import {useSelector} from 'react-redux'
import { GridProps } from "interfaces";
import { NavbarState } from "features/reducers/navbar.reducer";
export default function Grid({ children }: GridProps) {
   const navbarOffsetHeight = useSelector(
      ({ navbar }: NavbarState) => navbar.offsets.navbarOffsetHeight
   );
   return <Root marginTop={navbarOffsetHeight}>{children}</Root>;
}
