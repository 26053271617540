import styled from "styled-components";
export const Button = styled.button`
   padding: 0.8rem 2.5rem;
   font-size: 2.6rem;
   color: ${({ theme: { colors } }) => colors.white};
   background-color: ${({ theme: { colors } }) => colors.red};
   border-radius: 1rem;
   border: 0.2rem solid transparent;
   cursor: pointer;
   @media (min-width: ${({ theme: { media } }) => media.md}) {
      transition: ${({ theme: { transition } }) => transition};
      &:hover {
         transform: scale(1.05);
         background-color: ${({ theme: { colors } }) => colors.white};
         color: ${({ theme: { colors } }) => colors.red};
         border: 0.2rem solid ${({ theme: { colors } }) => colors.red};
      }
   }
`;
