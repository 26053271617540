import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "features/reducers/theme.reducer";
import navbarReducer from "features/reducers/navbar.reducer";
import windowReducer from "features/reducers/window.reducer";
export default configureStore({
   reducer: {
      theme: themeReducer,
      navbar: navbarReducer,
      window: windowReducer,
   },
});
