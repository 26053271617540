import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { animateScroll as scroll } from "react-scroll";

import { Root } from "./TopScroller.css";

export default function TopScroller() {
   const [isDisplayed, setIsDisplayed] = useState(false);
   const handleClick = () => scroll.scrollToTop({ duration: 1000 });
   const getDisplay = () => {
      const { innerHeight, scrollY } = window;
      if (scrollY < innerHeight) return setIsDisplayed(false);
      setIsDisplayed(true);
   };

   window.addEventListener("scroll", () => getDisplay());
   useEffect(
      () =>
         function cleanup() {
            window.removeEventListener("scroll", () => getDisplay);
         }
   );
   return (
      <Root isDisplayed={isDisplayed} onClick={handleClick}>
         <FontAwesomeIcon icon={faChevronUp} />
      </Root>
   );
}
