import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { IconLookup } from "@fortawesome/fontawesome-common-types/index";

import {
   Creators,
   Contact,
   Icon,
   Grid,
   Link,
   Logo,
   Paragraph,
   ParagraphWrapper,
   Root,
   SocialMedia,
} from "./Footer.css";
import { socialMedia } from "server";

import logo from "assets/img/logo_white.webp";
import instagramIcon from "assets/img/icon_instagram.webp";
import facebookIcon from "assets/img/icon_facebook.webp";

import { useTranslation } from "react-i18next"; // Importujemy hook do tłumaczeń

const contactParagraphs = [
   "Fundacja Po Prostu Da Się",
   "ul. Waleriana Łukasińskiego 22/7",
   "41-506 Chorzów",
   // "kontakt@poprostudasie.org",
   // "tel: 500572784 / 663196076",
   "KRS: 0000822672",
   "NIP: 6272766021",
];

export default function Footer() {
   const { t } = useTranslation(); // Inicjalizujemy hook do tłumaczeń

   return (
      <Root>
         <Grid>
            <Contact>
               <Logo src={logo} alt={t("footer.logoAlt")} />
               <ParagraphWrapper>
                  {contactParagraphs.map((paragraph) => (
                     <Paragraph key={paragraph} children={paragraph} />
                  ))}
               </ParagraphWrapper>
            </Contact>
            <SocialMedia>
               <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={socialMedia.facebook}
               >
                  <Icon src={facebookIcon} alt={t("footer.facebookIconAlt")} />
               </Link>
               <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={socialMedia.instagram}
               >
                  <Icon src={instagramIcon} alt={t("footer.instagramIconAlt")} />
               </Link>
               <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={socialMedia.youtube}
                  aria-label={t("footer.youtubeIconAlt")}
               >
                  <FontAwesomeIcon icon={faYoutube as IconLookup} />
               </Link>
               <Link href="privacy_policy">{t("footer.privacyPolicyLink")}</Link>
            </SocialMedia>
            <Creators>
               {t("footer.creatorsText", {
                  year: new Date().getFullYear(),
               })}
            </Creators>
         </Grid>
      </Root>
   );
}
