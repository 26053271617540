import styled from "styled-components";

export const Root = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   color: ${({ theme: { colors } }) => colors.white};
   &::after {
      content: "";
      background-color: ${({ theme: { colors } }) => colors.darkGray};
      height: 100%;
      width: 100vw;
      position: absolute;
      z-index: -1;
   }
`;
export const Title = styled.h2`
   color: ${({ theme: { colors } }) => colors.white};
   text-transform: uppercase;
   margin-top: 5rem;

   @media (max-width: ${({ theme: { media } }) => media.xlg}) {
      font-size: 5rem;
      padding: 0 2rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      margin-top: 8rem;
      font-size: 3rem;
      letter-spacing: 0;
      padding: 1rem;
   }
`;

export const List = styled.ul`
   margin: 10rem 0;
   list-style: none;
   display: flex;
   width: 100%;
   align-items: stretch;
   justify-content: space-between;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      flex-direction: column;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      margin: 5rem;
   }
`;
