import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next"; // Importuje hook do tłumaczenia

import { setValue } from "features/reducers/window.reducer";
import {
   Root,
   Paragraph,
   Navigation,
   NavigationButton,
   Wrapper,
} from "./CookiesPopup.css";

import cookiesPopup, { cookieQuestion, linkText } from "server/cookiesPopup";

export default function CookiesPopup() {
   const dispatch = useDispatch();
   const { t } = useTranslation(); // Inicjalizuje hook tłumaczenia
   const [isDisplayed, setIsDisplayed] = useState(true);
   const setIsCookiesAccepted = (value: boolean) =>
      dispatch(
         setValue({
            value,
            type: "isCookiesAccepted",
         })
      );
   const isClickedBefore =
      Cookies.get("cookiesAgreement") === "true" ||
      Cookies.get("cookiesAgreement") === "false";
   const handleClick = (value: string) => {
      Cookies.set("cookiesAgreement", value, { expires: 365 });
      setIsCookiesAccepted(value === "true");
      return setIsDisplayed(false);
   };

   return (
      <>
         {isDisplayed && !isClickedBefore && (
            <Root>
               <Wrapper>
                  <Paragraph>
                     {t(cookiesPopup)}{" "}
                     <Link to="privacy_policy" children={t(linkText)} />
                  </Paragraph>

                  <Paragraph children={t(cookieQuestion)} />
               </Wrapper>
               <Navigation>
                  <NavigationButton
                     children={t("cookiesPopup.agree")}
                     onClick={() => handleClick("true")}
                  />
                  <NavigationButton
                     children={t("cookiesPopup.disagree")}
                     onClick={() => handleClick("false")}
                  />
               </Navigation>
            </Root>
         )}
      </>
   );
}
