import styled from "styled-components";

export const Root = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   color: ${({ theme: { colors } }) => colors.white};
   background-color: ${({ theme: { colors } }) => colors.red};
   &::after {
      content: "";
      background-color: ${({ theme: { colors } }) => colors.red};
      height: 100%;
      width: 100vw;
      position: absolute;
      z-index: -1;
   }
`;
export const Title = styled.h2`
   color: ${({ theme: { colors } }) => colors.white};
   text-transform: uppercase;
   margin-top: 10rem;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      font-size: 5rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      font-size: 3rem;
      padding: 1rem;
      letter-spacing: 0;
      text-align: center;
   }
`;
export const Subtitle = styled.h3`
   text-transform: uppercase;
   font-weight: lighter;
   margin-bottom: 10rem;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      font-size: 2.8rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      font-size: 2.4rem;
      text-align: center;
      margin-bottom: 8rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      margin-top: 1rem;
      margin-bottom: 5rem;
      font-size: 1.9rem;
   }
`;
