export const metaData = {
   home: {
      description:
         "Fundacja prowadzi kreatywne szkolenia i warsztaty dla młodzieży, dorosłych, firm i organizacji. Jeśli chcesz wiedzieć na czym w praktyce polega podejście równościowe, antydyskryminacja, integracja różnych grup, podnieść kompetencje miękkie - zapraszamy do współpracy.",
      title: "Fundacja Po Prostu Da Się",
   },
   cooperation: {
      description:
         "Zorganizujemy odjazdowe warsztaty wyjazdowe, szkolenia antydyskryminacyjne lub cykl szkoleń, wydarzenia integracyjne dla twojej firmy, szkoły, instytucji. A może potrzebujesz partnera w projekcie lokalnym, ponadregionalnym, międzynarodowym?",
      title: "Współpraca - Fundacja Po Prostu Da Się",
   },

   donation: {
      description:
         "Zostań mecenasem, wesprzyj równy dostęp do kultury, wiedzy i narzędzi edukacyjnych. Przekaż darowiznę a z twoją pomocą nasza organizacja pozarządowa udowodni, że po prostu da się zmienić świat na lepsze.",
      title: "Wesprzyj - Fundacja Po Prostu Da Się",
   },
   privacyPolicy: {
      description: "",
      title: "Polityka Prywatności - Fundacja Po Prostu Da Się",
   },
};
