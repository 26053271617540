import styled from "styled-components";

export const Root = styled.header`
   grid-area: header;
   margin: 0;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 4rem 0;
   width: 100%;
   min-height: 80vh;
   position: relative;
   left: 0;
   font-size: 4rem;
   z-index: 1;
   &::after {
      content: "";
      background-color: ${({ theme: { colors } }) => colors.darkGray};
      height: 100%;
      width: 100vw;
      position: absolute;
      z-index: -1;
   }
   a {
      align-self: start;
   }
   @media (max-width: ${({ theme: { media } }) => media.xxlg}) {
      min-height: 75vh;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      min-height: 100vh;
      a {
         align-self: center;
      }
   }
`;

export const MonsterText = styled.h1`
   font-size: 6rem;
   color: white;
   text-transform: uppercase;
   width: 60%;
   align-self: start;
   @media (max-width: ${({ theme: { media } }) => media.xlg}) {
      margin: 5rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.xlg}) {
      font-size: 5rem;
      width: 75%;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      font-size: 4rem;
      text-align: center;
      width: 100%;
      margin: 5rem 0;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      font-size: 2.8rem;
      letter-spacing: 0;
      line-height: 4rem;
   }
`;

export const Button = styled.button`
   background-color: ${({ theme: { colors } }) => colors.red};
   color: ${({ theme: { colors } }) => colors.white};
   margin: 0;
   padding: 1.5rem 2rem;
   border: none;
   border-radius: 1rem;
   font-size: 2.3rem;
   align-self: start;
   cursor: pointer;
   font-weight: bold;
   width: 20rem;
   @media (max-width: ${({ theme: { media } }) => media.xlg}) {
      margin: 0 5rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      width: 30rem;
   }
`;
