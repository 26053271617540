import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import {
   CurrentFlag,
   Flag,
   FlagContainer,
   FlagOption,
   FlagPicker,
   Root,
   ThemeSwitcher,
   ThemeSwitcherButton,
} from "./AccessibilityNav.css";
import {
   switchTheme,
   toggleFontSize,
   switchPayloadType,
   ThemeState,
} from "features/reducers/theme.reducer";
import { languages } from "server";

function AccessibilityNav() {
   // const [isDisplayed, setIsDisplayed] = useState(true);
   const [activeIndex, setActiveIndex] = useState(0);
   const [langIsOpen, setLangIsOpen] = useState(false);
   const toggleIsOpen = () => setLangIsOpen(!langIsOpen);

   ///redux
   const dispatch = useDispatch();
   const handleTheming = (payload: switchPayloadType) =>
      dispatch(switchTheme(payload));
   const handleFontSizing = () => dispatch(toggleFontSize());
   const { fontSize, currentThemeName } = useSelector(
      ({ theme }: ThemeState) => theme
   );

   const activeFlag = languages[activeIndex].flag;
   return (
      <Root isDisplayed={true}>
         <ThemeSwitcher>
            <ThemeSwitcherButton
               children={fontSize >= 3 ? "A-" : "A+"}
               onClick={handleFontSizing}
            />
            <ThemeSwitcherButton
               children="A"
               isColored={currentThemeName === "darkYellow"}
               style={{
                  color: "yellow",
                  backgroundColor: "#111",
               }}
               onClick={() => handleTheming("darkYellow")}
            />
            <ThemeSwitcherButton
               children="A"
               isColored={currentThemeName === "dark"}
               style={{
                  color: "white",
                  backgroundColor: "#111",
               }}
               onClick={() => handleTheming("dark")}
            />
         </ThemeSwitcher>
         <FlagContainer onClick={() => toggleIsOpen()} langIsOpen={langIsOpen}>
            <CurrentFlag>
               <Flag background={activeFlag} />
               <FontAwesomeIcon icon={faChevronDown} />
            </CurrentFlag>

            <FlagPicker langIsOpen={langIsOpen}>
               {languages.map(({ flag }, index) =>
                  activeIndex !== index ? (
                     <FlagOption
                        key={flag}
                        onClick={() => setActiveIndex(index)}
                     >
                        <Flag background={flag} />
                     </FlagOption>
                  ) : null
               )}
            </FlagPicker>
         </FlagContainer>
      </Root>
   );
}

export default AccessibilityNav;
