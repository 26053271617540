import { useQuery } from "react-query";
import { Logo, Title, Root } from "./SliderSection.css";
import { LoadingIndicator } from "components";
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next"; // Importuj useTranslation

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const url = "/api/logos/get";

export default function SliderSection() {
  const { isLoading, error, data } = useQuery<unknown, unknown, fetchedData>(
    "logos",
    () =>
      fetch(url).then((res) => {
        if (res.ok) return res.json();
        throw new Error(res.statusText);
      })
  );

  const { t } = useTranslation(); // Użyj useTranslation do tłumaczenia tekstu

  return (
    <Root>
      <Title children={t("sliderSection.title")} />{" "}
      {!isLoading && error === null && data ? (
        <Swiper
          modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
          autoplay={true}
          navigation={true}
          spaceBetween={5}
          pagination={{ clickable: true }}
          loop={true}
          breakpoints={{
            300: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1600: {
              slidesPerView: 4,
            },
          }}
        >
          {data &&
            data
              .sort((a, b) => parseInt(a.priority) - parseInt(b.priority))
              .map(({ _id, name, path, href }) => {
                return href !== "null" ? (
                  <SwiperSlide key={_id}>
                    <a key={_id} href={href} target="_blank" rel="noreferrer">
                      <Logo src={path} alt={name} />
                    </a>
                  </SwiperSlide>
                ) : (
                  <SwiperSlide key={_id}>
                    <Logo src={path} alt={name} />{" "}
                  </SwiperSlide>
                );
              })}
        </Swiper>
      ) : (
        <LoadingIndicator
          message={
            error
              ? t("sliderSection.errorMessage")
              : t("sliderSection.loadingMessage")
          }
        />
      )}
    </Root>
  );
}

type fetchedData = {
  name: string;
  path: string;
  _id: string;
  href: string;
  priority: string;
}[];
