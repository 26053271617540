import { useEffect, useRef } from "react";
import { Element } from "react-scroll";
import { useDispatch } from "react-redux";
import { setOffset } from "features/reducers/navbar.reducer";
import { PersonsWrapper, Title, Root } from "./AboutUsSection.css";
import { Person } from "components";
import { useTranslation } from "react-i18next";
import contentData from "server/aboutUsText";

export default function AboutUsSection() {
  const { person } = contentData;
  const rootElement = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (rootElement.current) {
      const refElement = rootElement.current as HTMLElement;
      const offsetTop = refElement.offsetTop;
      dispatch(setOffset({ type: "aboutUsOffset", value: offsetTop }));
    }
  }, [dispatch]);

  return (
    <Element name="aboutUs" style={{ gridArea: "aboutUs" }}>
      <Root ref={rootElement}>
        <Title children={t("aboutUsSection.title")} />
        <PersonsWrapper>
          {" "}
          {person.map((person) => (
            <Person key={person.name} person={person} />
          ))}
        </PersonsWrapper>
      </Root>
    </Element>
  );
}
