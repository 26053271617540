import styled from "styled-components";

const Root = styled.button.attrs(() => ({
   "aria-label": "Przewiń stronę na samą górę",
}))<topScrollerProps>`
   ${({ theme: { colors, media, transition }, isDisplayed }) => `
   display: flex;
   align-items: center;
   justify-content: center;
   height: 5rem;
   width: 5rem;
   position: fixed;
   right: 3rem;
   bottom: 4rem;
   transition: ${transition};
   opacity: ${isDisplayed ? "1" : "0"};
   pointer-events: ${isDisplayed ? "auto" : "none"};
   border-radius: 50%;
   border:none;
   color:white;
   background: ${colors.green};
   cursor: pointer;
   z-index: 10;
   font-size: 5rem;
   & svg  {
      font-size: 1.8rem;
   }
   &:hover {
      background: ${colors.green};
   }
   @media (max-width:${media.md}){
      bottom: 1rem;
      right: 1rem;
   }
`}
`;

export { Root };

type topScrollerProps = { isDisplayed: boolean };
