import { List, Root, Title } from "./Contact.css";
import { ContactListElement } from "components";
import contact from "server/contact";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <Root>
      <Title>{t("contact.title")}</Title>
      <List>
        {contact.map((listElement) => (
          <ContactListElement
            key={listElement.title.pl}
            listElement={listElement}
          />
        ))}
      </List>
    </Root>
  );
}
