import styled from "styled-components";

export const Root = styled.li`
   padding: 1rem;
   display: flex;
   flex-basis: 25%;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
   border-left: 1px solid #444;
   &:last-child {
      border-right: 1px solid #444;
   }
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      margin-top: 2rem;
      flex-direction: row;
      padding: 1rem 2rem;
      justify-content: space-between;
      align-items: stretch;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      flex-wrap: wrap;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      margin-top: 1rem;
   }
`;

export const Title = styled.h3`
   height: 8rem;
   font-weight: lighter;
   text-transform: uppercase;
   font-size: 3rem;
   text-align: center;
   width: 100%;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: unset;
      width: 30%;
      padding: 1rem;
      font-size: 1.8rem;
      font-weight: bold;
      border-right: 1px solid ${({ theme: { colors } }) => colors.white};
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      width: 70%;
      border: none;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      font-size: 2rem;
   }
`;
export const Triangle = styled.img`
   max-width: 15rem;
   margin: 5rem 0;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      margin: 0;
      padding: 1rem;
      width: 20%;
      order: -1;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      width: 30%;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      width: unset;
      height: 7rem;
   }
`;
export const Paragraph = styled.p`
   margin: 0.5rem 0;
   text-align: center;
   text-transform: uppercase;
   font-size: 1.8rem;
   width: 100%;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      text-align: left;
      margin: 0.2rem 0;
      font-size: 1.6rem;
      width: 80%;
   }
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      font-weight: normal;
      line-height: 2.8rem;
      margin: 0;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      width: unset;
   }
`;

export const Icon = styled.img`
   max-width: 4rem;
   margin: 1rem;
   filter: brightness(0) invert(1);
   transition: ${({ theme: { transition } }) => transition};
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      max-width: 3.5rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      max-width: 4rem;
      margin: 2rem;
   }
`;
export const SocialIcon = styled.a`
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 2.1rem;
   width: 100%;
   transition: ${({ theme: { transition } }) => transition};
   &:hover {
      color: ${({ theme: { colors } }) => colors.red};
      img {
         filter: none;
      }
   }
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      font-size: 1.8rem;
   }
`;

export const ParagraphsWrapper = styled.div`
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      padding: 0 1rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      padding: 4rem 2rem;
      width: 100%;
      border-top: 1px solid ${({ theme: { colors } }) => colors.black};
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      padding: 1.5rem;
   }
`;
