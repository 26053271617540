export const plMonths = [
   "styczeń",
   "luty",
   "marzec",
   "kwiecień",
   "maj",
   "czerwiec",
   "lipiec",
   "sierpień",
   "wrzesień",
   "październik",
   "listopad",
   "grudzień",
];
export const getMonthAndYear = (date: Date): string => {
   const year = date.getFullYear();
   const currentYear = new Date().getFullYear();

   return `${plMonths[date.getMonth()]}${
      currentYear === year ? "" : ` ${year}`
   }`;
};
