import { createSlice } from "@reduxjs/toolkit";
export const navbarSlice = createSlice({
   name: "navbar",
   initialState: {
      activeElement: "none",
      offsets: {
         headerOffset: 0,
         aboutUsOffset: 0,
         timelineOffset: 0,
         contactOffset: 0,
         navbarOffsetHeight: 0,
         scrolledNavbarOffsetHeight:65,
      },
   },
   reducers: {
      setOffset: (state, { payload }: setOffsetAction) => {
         state.offsets[payload.type] = payload.value;
      },
      setActiveElement: (state, { payload }: setActiveElementAction) => {
         state.activeElement = payload;
      },
   },
});

export const { setOffset, setActiveElement } = navbarSlice.actions;
export type setOffsetType =
   | "headerOffset"
   | "aboutUsOffset"
   | "timelineOffset"
   | "contactOffset"
   | "navbarOffsetHeight"
   | "scrolledNavbarOffsetHeight";
export type activeElementType =
   | "none"
   | "header"
   | "aboutUs"
   | "timeline"
   | "contact"
   | "wspolpraca";

type setOffsetAction = { payload: { type: setOffsetType; value: number } };
type setActiveElementAction = {
   payload: activeElementType;
};

export type NavbarState = {
   navbar: ReturnType<typeof navbarSlice.getInitialState>;
};
export default navbarSlice.reducer;
