import { useSwiper } from "swiper/react";
import { useEffect } from "react";
export default function SwipeToFirst({ activeYear }: swipeToFirstProps) {
   const swiper = useSwiper();
   useEffect(() => {
      swiper.slideTo(0);
   }, [activeYear, swiper]);
   return <></>;
}
type swipeToFirstProps = {
   activeYear: string;
};
