import styled from "styled-components";

import rootBackground from "assets/img/collage.webp";
import rootBackgroundMobile from "assets/img/collage_mobile.webp";
import rootBackgroundTablet from "assets/img/collage_tablet.webp";

const Root = styled.section`
   display: flex;
   flex-direction: column;
   margin: 0;
   padding: 4rem 0;
   // height: 40rem;
   width: 100%;
   min-height: 80vh;
   position: relative;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 4rem;
   z-index: 1;
   &::after {
      content: "";
      background: url(${rootBackground});
      background-size: cover;
      height: 100%;
      width: 100vw;
      position: absolute;
      z-index: -1;
      @media(max-width:${({theme:{media}})=>media.md}){
         background: url(${rootBackgroundTablet});
         background-size: 100% auto;
         background-repeat: no-repeat;

      }
      @media(max-width:${({theme:{media}})=>media.sm}){
         background: url(${rootBackgroundMobile});
         background-size: 100% auto;
         background-repeat: no-repeat;
      }
   }
   a {
      align-self: end;
   }
   @media (max-width: ${({ theme: { media } }) => media.xlg}) {
      padding: 0 5rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      min-height: 100vh;
      height: unset;
      a {
         align-self: center;
      }
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      padding: 0 1rem;
   }
`;
const MonsterText = styled.h1`
   width: 90%;
   align-self: start;
   text-transform: uppercase;
   font-size: 5rem;
   color: white;
   @media (max-width: ${({ theme: { media } }) => media.lg}) {
      font-size: 4.5rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      font-size: 4rem;
      letter-spacing: 0.1rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      width: 100%;
      text-align: center;
      font-size: 3.5rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      font-size: 3rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.mobile}) {
      font-size: 2.8rem;
   }
`;
const Button = styled.button`
   padding: 1.5rem 5rem;
   width: 20rem;
   background-color: ${({ theme: { colors } }) => colors.red};
   color: ${({ theme: { colors } }) => colors.white};
   margin: 0;
   border: none;
   border-radius: 1rem;
   font-size: 2.3rem;
   align-self: end;
   cursor: pointer;
   font-weight: bold;
   margin-bottom: 4rem;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      align-self: center;
      width: 30rem;
   }
`;
const Paragraph = styled.p`
   color: ${({ theme: { colors } }) => colors.white};
   font-size: 2.4rem;
   margin: 5rem 0;
   width: 60%;
   text-align: justify;
   align-self: start;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      width: 100%;
      margin: 2rem 0;
      font-size: 2.1rem;
      text-align: left;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      font-size: 1.8rem;
      text-align: center;
   }
`;
export { Button, MonsterText, Paragraph, Root };
