import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActiveElement } from "features/reducers/navbar.reducer";
import {
   Action,
   ActionImage,
   Button,
   ButtonTitle,
   ButtonParagraph,
   Root,
} from "./ActionsSection.css";
import actionImage from "assets/img/action.webp";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next"; // Importujemy hook do tłumaczenia

function ActionsSection() {
   const dispatch = useDispatch();
   const { t } = useTranslation(); // Inicjalizujemy hook do tłumaczenia

   return (
      <Element name="actions" style={{ gridArea: "actions" }}>
         <Root>
            <Action>
               <ActionImage src={actionImage} alt={t("actionImage.alt")} />
               <Link
                  to="/wspolpraca"
                  onClick={() => dispatch(setActiveElement("none"))}
               >
                  <Button>
                     <ButtonTitle children={t("actions.cooperation")} />
                     <ButtonParagraph children={t("actions.cooperationDescription")} />
                  </Button>
               </Link>
            </Action>
            <Action>
               <ActionImage src={actionImage} alt={t("actionImage.alt")} />
               <Link
                  to="/wesprzyj"
                  onClick={() => dispatch(setActiveElement("none"))}
               >
                  <Button>
                     <ButtonTitle children={t("actions.support")} />
                  </Button>
               </Link>
            </Action>
         </Root>
      </Element>
   );
}

export default ActionsSection;
