import { useSelector } from "react-redux";
import { WindowState } from "features/reducers/window.reducer";
import { Helmet } from "react-helmet";
import { metaData } from "server/metaData";
import { useLocation } from "react-router-dom";

export default function Head() {
   const location = useLocation();
   const getKeyByPath: (arg: string) => string = (pathname: string) => {
      switch (pathname) {
         case "/wesprzyj":
            return "donation";
         case "/wspolpraca":
            return "cooperation";
         case "/privacy_policy":
            return "privacyPolicy";
         default:
            return "home";
      }
   };
   const metaDataKey = getKeyByPath(location.pathname) as keyof typeof metaData;
   const { isCookiesAccepted } = useSelector(
      ({ window }: WindowState) => window
   );
   return (
      <Helmet>
         <meta name="description" content={metaData[metaDataKey].description} />
         <title>{metaData[metaDataKey].title}</title>
      </Helmet>
   );
}
