import { Root, Title, IntroductoryText, Paragraph } from "./SubpageHeader.css";

export default function SubpageHeader({
   background,
   headerText,
   title,
}: subpageProps) {
   return (
      <Root background={background}>
         <Title children={title} />
         {headerText && (
            <IntroductoryText>
               {headerText.map((text) => (
                  <Paragraph children={text} key={text} />
               ))}
            </IntroductoryText>
         )}
      </Root>
   );
}

type subpageProps = {
   background: string;
   title: string;
   headerText?: string[];
};
