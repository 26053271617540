import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link as LinkScroller } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { WindowState } from "features/reducers/window.reducer";
import {
  setActiveElement,
  activeElementType,
  NavbarState,
  setOffset,
} from "features/reducers/navbar.reducer";
import {
  DonationButtonWrapper,
  Hamburger,
  List,
  Logo,
  NavButton,
  Root,
} from "./Navbar.css";
import { DonationButton } from "components";
import NavDropDown from "./NavDropDownScrolled";
import OutsideClicker from "features/OutsideClicker";
import { navButtons } from "server";
import fixedLogo from "assets/img/fixedLogo.webp";
import LanguageSwitcher from "../LanguageSwicher/LanguageSwitcher";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobile } = useSelector(({ window }: WindowState) => window);
  const activeNavButton = useSelector(
    ({ navbar }: NavbarState) => navbar.activeElement
  );
  const scrolledNavbarOffsetHeight = useSelector(
    ({ navbar }: NavbarState) => navbar.offsets.scrolledNavbarOffsetHeight
  );
  const rootEl = useRef<HTMLElement>(null);
  const newPath = location.pathname !== "/";

  const { t } = useTranslation();

  const [isOpenHamburger, setIsOpenHamburger] = useState(false);
  const [dropIsOpened, setDropIsOpened] = useState(false);
  const scrollOffset =
    rootEl.current !== null && !isMobile
      ? -rootEl.current.offsetHeight
      : -scrolledNavbarOffsetHeight;

  const [selectedLanguage, setSelectedLanguage] = useState("pl");

  const changeLanguage = () => {
    const newLanguage = selectedLanguage === "pl" ? "en" : "pl";
    setSelectedLanguage(newLanguage);
  };

  useEffect(() => {
    if (!rootEl.current) return;
    const { offsetHeight } = rootEl.current;
    dispatch(
      setOffset({
        type: "navbarOffsetHeight",
        value: isMobile ? scrolledNavbarOffsetHeight : offsetHeight,
      })
    );
  }, [dispatch, isMobile, scrolledNavbarOffsetHeight]);

  useEffect(() => {
    setIsOpenHamburger(false);
  }, [location]);

  const outsideClickHandle = () => setIsOpenHamburger(false);

  return (
    <OutsideClicker callback={isMobile ? outsideClickHandle : undefined}>
      <Root
        ref={rootEl}
        isOpenHamburger={isOpenHamburger}
        subHamburgerIsOpened={dropIsOpened}
      >
        <Hamburger onClick={() => setIsOpenHamburger(!isOpenHamburger)}>
          <FontAwesomeIcon icon={faBars} />
          <Logo src={fixedLogo} alt="logo fundacji po prostu da się" />
        </Hamburger>
        <List isOpenHamburger={isOpenHamburger}>
          <Link className="logo" to="/" state={{ isChangedPath: newPath }}>
            <Logo src={fixedLogo} alt="logo fundacji po prostu da się" />
          </Link>

          {navButtons.map(({ id, name, scrollTo, link }) => (
            <React.Fragment key={id}>
              {name === "o nas" ? (
                <NavDropDown
                  isOpened={dropIsOpened}
                  setIsOpened={setDropIsOpened}
                  activeNavButton={activeNavButton}
                  setParentisOpened={setIsOpenHamburger}
                  name={t(name)} // Użyj t do przetłumaczenia nazwy
                  scrollTo={scrollTo}
                  parentIsOpen={isOpenHamburger}
                />
              ) : link.length !== 0 ? (
                <Link
                  to={link}
                  state={{ isChangedPath: location.pathname !== "/" }}
                  className={location.pathname === link ? "active" : ""}
                  onClick={() => dispatch(setActiveElement("none"))}
                >
                  <NavButton children={t(name)} />{" "}
                  {/* Użyj t do przetłumaczenia nazwy */}
                </Link>
              ) : location.pathname !== "/" ? (
                <Link
                  to="/"
                  state={{
                    isChangedPath: location.pathname !== "/",
                    scrollParameter: scrollTo,
                  }}
                  className={activeNavButton === scrollTo ? "active" : ""}
                  onClick={() => {
                    setIsOpenHamburger(false);
                    dispatch(setActiveElement(scrollTo as activeElementType));
                  }}
                >
                  <NavButton children={t(name)} />{" "}
                  {/* Użyj t do przetłumaczenia nazwy */}
                </Link>
              ) : (
                <LinkScroller
                  to={scrollTo}
                  duration={1000}
                  smooth={true}
                  onClick={() => setIsOpenHamburger(false)}
                  offset={scrollOffset}
                >
                  <NavButton children={t(name)} />{" "}
                  {/* Użyj t do przetłumaczenia nazwy */}
                </LinkScroller>
              )}
            </React.Fragment>
          ))}
          <DonationButtonWrapper>
            <DonationButton />
          </DonationButtonWrapper>

          <LanguageSwitcher
            selectedLanguage={selectedLanguage}
            onChangeLanguage={() => changeLanguage()}
          />
        </List>
      </Root>
    </OutsideClicker>
  );
}
