import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
  resources: {
    en: {
      translation: {
        "co robimy?": "What We Do?",
        współpraca: "Collaborate",
        "o nas": "About Us",
        "nasze projekty": "Projects",
        kontakt: "Contact",
        "donationButton.support": "Support",
        "recruitmentSlider.noActiveRecruitmentMessage": "No open calls at the moment. See what else we can do for you.",
        "monster2.text": "We offer creative training for youth and adults.",
        "monster2.paragraph":
          "We teach openness, equal treatment, and skill development through outdoor and on-site workshops. We creatively integrate groups (employees/students/strangers) and host engaging artistic events.",
        "monster2.button": "Get involved",

        "icons.type": "Type",
        "icons.forWho": "For Whom",
        "icons.duration": "Minimum duration",
        "service.title": "Title",

        "header.text": "Embracing Diversity.",
        "header.button": "Learn more",

        "aboutUsSection.title": "Out Team",

        "actions.cooperation": "Collaborate",
        "actions.cooperationDescription": "Check out what we can do for you",
        "actions.support": "Support Us",
        "actionImage.alt": "Action Arrow",

        "contact.title": "Contact",

        "cookiesPopup.agree": "I agree",
        "cookiesPopup.disagree": "I disagree",
        "cookieQuestion":"Do you consent to storing information and accessing information stored in cookies?",
        "cookiesPopup":"This website uses 'cookies', including essential cookies that ensure its proper functionality, as well as cookies from third parties. You can change your web browser settings at any time to disable the option for storing cookies. For detailed information about cookies on this website, you can read our privacy policy.",

        "footer.logoAlt": "Foundation Po Prostu Da Się Logo",
        "footer.facebookIconAlt": "Facebook Icon",
        "footer.instagramIconAlt": "Instagram Icon",
        "footer.youtubeIconAlt": "Our YouTube Channel",
        "footer.privacyPolicyLink": "Privacy Policy",
        "footer.creatorsText":
          "© {{year}} All rights reserved. Website designed in collaboration with Magda Bremer and Mateusz Łuczkowski. Developed by Mateusz Łuczkowski.",

        "newsletter.title": "STAY UPDATED",
        "newsletter.subtitle": "STAY INFORMED ABOUT OUR ACTIVITIES",
        "newsletter.sendingMessage": "Sending the form. Please wait...",

        "newsletterForm.namePlaceholder": "Name",
        "newsletterForm.emailPlaceholder": "Email",
        "newsletterForm.agreementText":
          "I consent to receiving commercial information electronically, to the email address provided by me, as defined in Article 10(1) of the Act of July 18, 2002, on the provision of electronic services, from the Foundation under the name YOU SIMPLY CAN FOUNDATION.",
        "newsletterForm.emailRequired": "This field cannot be empty",
        "newsletterForm.invalidEmail": "Invalid email address",
        "newsletterForm.nameRequired": "This field cannot be empty",
        "newsletterForm.agreementRequired":
          "Consent to processing your data is mandatory",
        "newsletterForm.recaptchaRequired": "Confirm that you are not a robot",
        "newsletterForm.submitButton": "Sign up",

        "newsletterPostMessage.successTitle": "Thank you!",
        "newsletterPostMessage.errorTitle": "Oops! Something went wrong.",
        "newsletterPostMessage.emailExistTitle": "Oops! Something went wrong.",
        "newsletterPostMessage.successParagraph":
          "Your email has been successfully added to the list of users subscribing to our newsletter.",
        "newsletterPostMessage.errorParagraph":
          "Refresh the page and try again.",
        "newsletterPostMessage.emailExistParagraph":
          "You are already our active subscriber.",

        "timelineCard.exampleEventTitle": "Example Event",
        "timelineCard.exampleEventDate": "January 1, 2023",
        "timelineCard.exampleEventDescription":
          "This is an example event description.",
        "timelineCard.clickToLearnMore": "Click to learn more",

        "timelineSection.whatWeDo": "DISCOVER WHAT WE DO",
        "timelineSection.loadingData": "Loading data...",
        "timelineSection.loadingError":
          "Oops... something went wrong. Error loading data from the server.",

        "banner.title": "PARTICIPATE",
        "banner.whiteTriangleAlt": "white triangle",
        "banner.subtitle": "Below, you can find open calls for participants ",

        "values.title": "Values",

        "contentData.header":
          "In our work, inclusivity, openness, anti-schematism, and the well-being of people and the environment are the most important. We strive to involve everyone in our activities, regardless of gender, origin, appearance, disability, age, sexual orientation, or other identity characteristics. We discover new ways to solve social problems we observe. We work creatively, with respect for the natural environment. We aim to spread knowledge about human rights and their observance.",
        "aboutUs.header":
          "In our work, inclusivity, openness, non-conformity, and the well-being of both people and the environment are paramount. We strive to involve everyone in our initiatives, regardless of gender, origin, appearance, ability, age, sexual orientation, or other identity traits. We explore new ways to address the social issues we observe. We act creatively while respecting the natural environment. We aim to promote knowledge about human rights and their enforcement.",
        "aboutUs.person.0.name": "Antonina Federowicz",
        "aboutUs.person.0.description":
          "Co-founder of the Po Prostu Da Się Foundation, where she coordinates and implements projects. An enthusiast of new technologies, which she successfully introduces in the foundation (she was responsible, among other things, for implementing innovations in a company co-financed under the European Funds). She works as an analyst, instructor of artistic activities involving groups at risk of exclusion. She co-creates projects of theatrical education among children, youth, and seniors. She is an actress at the City Theater in Gliwice, a graduate of the Academy of Theater Arts in Wrocław and the Warsaw School of Economics. She spent her childhood in the theater, which developed her openness, curiosity, and courage. Culture is, for her, the best space for getting to know another person and improving oneself. She would like it to be a space where everyone can find their place.",

        expand: "read more",
        collapse: "collapse",

        "coop.title": "Let's Collaborate",
        "services.title": "See What We Can Do for You",

        "sliderSection.title": "WE COLLABORATE WITH:",
        "sliderSection.errorMessage": "Oops! Something went wrong.",
        "sliderSection.loadingMessage": "Loading...",

        "gdzie nas znajdziesz?": "WHERE TO FIND US?",
        "nasze dane": "OUR DETAILS",
        "skontaktuj się z nami": "Contact Us",
        "tutaj możesz nas wspomóc": "YOU CAN SUPPORT US HERE",
        "fundacja po prostu da się": "Foundation Just Can Do It",
        "ul. Waleriana Łukasińskiego 22/7": "ul. Waleriana Łukasińskiego 22/7",
        "41-506 Chorzów": "41-506 Chorzów",
        "NIP: 6272766021": "Tax ID: 6272766021",
        // Dodaj tłumaczenia dla paragrafów
        "email: kontakt@poprostudasie.org": "Email: contact@poprostudasie.org",
        "tel. +48 663 196 076": "Phone: +48 663 196 076",
        "Santander Bank Polska SA": "Santander Bank Polska SA",
        "02 1090 2688 0000 0001 4416 6731":
          "Bank Account Number: 02 1090 2688 0000 0001 4416 6731",
        "KRS 0000338803": "National Court Register: 0000338803",

        "Esencja": "Essence",
        "Warsztaty skrojone na miarę. Podczas intensywnych kilku godzin popracujemy nad wybranym zagadnieniem (np. podejściem równościowym, integracją grupy, rozbudzeniem kreatywności, komunikacją interpersonalną w środowisku międzykulturowym, odkryciem indywidualnego potencjału). Możemy w interaktywny sposób popracować nad komunikacją bez przemocy (nvc), poruszyć takie tematy jak TUS (trening umiejętności społecznych), team building czy przeprowadzić trening antydyskryminacyjny.":
          "Tailored Workshops. During these intensive few hours, we'll work on a chosen topic (e.g., equality approaches, group integration, sparking creativity, intercultural interpersonal communication, discovering individual potential). We can interactively explore non-violent communication (NVC), address subjects like social skills training (SST), team building, or conduct anti-discrimination training.",
        "szkolenie stacjonarne": "on-site training",
        "5 - 20 osób": "5-20 people",
        "Intensywne warsztaty": "Intensive workshops",
        "Spotkajmy się we wskazanym przez Ciebie miejscu i popracujmy kreatywnie nad tematem, który wspólnie wybierzemy. Zapomnij o nudnym wykładzie i siedzeniu w miejscu - do każdego zagadnienia przygotowujemy aktywizujące ćwiczenia  i na bieżąco reagujemy na potrzeby grupy. W czasie warsztatu wykorzystujemy autorski dobór metod, czerpiących m.in. z improwizacji scenicznej, mindfulness czy edukacji osób dorosłych (non formal education). Od Ciebie zależy czy wolisz jeden, intensywny, kreatywny dzień czy chcesz spotykać się z nami cyklicznie.":
          "Let’s meet at the location of your choice and collaborate creatively on a topic we’ll choose together. Forget about dull lectures and sitting in one place - for each subject, we prepare engaging exercises and adapt to the group’s needs in real-time. During the workshop, we employ our unique selection of methods, drawing from techniques such as stage improvisation, mindfulness, and non-formal adult education. It’s up to you whether you prefer a single, intensive, creative day or want to meet with us regularly.",
        "Partnerstwo": "Partnership",
        "ngo/instytucja kultury": "NGOs/Cultural Institutions",
"3-7 dni":"3-7 days",
"Jako organizacja pozarządowa mamy duże doświadczenie w przygotowywaniu (w tym pisaniu wniosków), organizowaniu i prowadzeniu różnorodnych projektów. Nasze główne obszary działania to: prawa człowieka w tym prawa kobiet i mniejszości, równe traktowanie, edukacja artystyczna. Mówimy po polsku i angielsku. Chętnie podzielimy się naszą wiedzą i zasobami, żeby wspólnie tworzyć nową jakość - napiszmy wspólnie wniosek, przygotujmy wydarzenie, poprowadźmy warsztaty. Możemy nawiązać współpracę długoterminową lub dotyczącą konkretnego projektu/ przedsięwzięcia.":
"As a non-governmental organization, we have extensive experience in preparing (including grant writing), organizing, and managing various projects. Our main areas of focus include human rights, including women's and minority rights, equal treatment, and artistic education. We are fluent in both Polish and English. We are eager to share our knowledge and resources to collaboratively create new quality – let's write a proposal together, prepare an event, or conduct workshops. We are open to long-term partnerships or collaborations on specific projects/initiatives.",
"Rusz w Busz": "Embark into the Wild",
"szkolenie wyjazdowe": "outdoor training",
"Niezapomniane doświadzczenie outdoroowe. To coś więcej niż integracja czy szkolenie. Rusz w Busz to zielona droga z mentorem, która pomoże wydobyć wasze mocne strony. Podczas wyjazdu certyfikowane instruktorki i mentorki przeprowadzą kompleksowe warsztaty rozwijające wybrane kompetencje miękkie i przeciwdziałające wypaleniu zawodowemu. Szkolenie wyjazdowe daje możliwości pogłębionej pracy zarówno nad indywidualnymi celami jak i poprawia dynamikę funkcjonowania w grupie.":
"Unforgettable Outdoor Experience. It's more than just team building or training. 'Embark into the Wild' is a green path with mentors that will help uncover your strengths. During the trip, certified instructors and mentors will conduct comprehensive workshops to develop selected soft skills and prevent burnout. The outdoor training offers the opportunity for in-depth work on individual goals as well as improving group dynamics.",

"Chętnie dzielimy się naszym doświadczeniem i pokazujemy w praktyce jak prowadzić działania równościowe i antydyskryminacyjne.":" We're eager to share our experience and demonstrate how to implement equality and anti-discrimination initiatives in practice.",
"Możesz zaprosić nas do swojej firmy, szkoły, organizacji czy instytucji. A może chcesz żebyśmy zorganizowały dla Ciebie i Twojego zespołu warsztaty wyjazdowe, nieszablonowe wydarzenie integracyjne lub przygotowały dedykowany cykl szkoleń?":
"You can invite us to your company, school, organization, or institution. Perhaps you'd like us to organize off-site workshops, unconventional team-building events, or a customized training program for you and your team?",
"Mamy też duże doświadczenie w prowadzeniu i koordynowaniu projektów - chętnie wspólnie poszukamy możliwości realizacji pomysłów. Jesteśmy otwarte na współpracę lokalną, ponadregionalną i międzynarodową.":
"We also have extensive experience in project management and coordination, and we're open to exploring opportunities to bring your ideas to life. We welcome collaboration at the local, regional, and international levels.",




        "bankTransferInfo.title": "Transfer Details",
        "bankTransferInfo.bankName": "SANTANDER BANK POLSKA SA",
        "bankTransferInfo.bankAccount": "PL 02 1090 2688 0000 0001 4416 6731",
        "bankTransferInfo.bankTitle":
          "In the title: DONATION FOR STATUTORY PURPOSES",
        "bankTransferInfo.foundationName": "You Simply Can Foundation",
        "bankTransferInfo.foundationAddress":
          "UL. WALERIANA ŁUKASIŃSKIEGO 22/7",
        "bankTransferInfo.foundationCity": "41-506 CHORZÓW",

        "donationAgreement.agreementText":
          "In connection with my donation, I consent to the processing of my personal data by the Foundation Po Prostu Da Się, located at ul. Waleriana Łukasińskiego 22/7 in Chorzów.",
        "donationAgreement.agreementText2":
          "More about the processing of personal data by the Foundation can be found in our",
        "donationAgreement.privacyPolicyLink": "privacy policy.",

        "donationPage.statusTitle": "Payment Status",
        "donationPage.donateTitle": "Make a Donation",
        "donationPage.loadingMessage": "Loading...",
        "noActiveRecruitmentMessage":"No open calls at the moment. See what else we can do for you. ",
        "amountButton.currency": "PLN",
        "donationNav.bankTransfer": "Bank Transfer",
        "recruitmentSlider.loadingMessage":"Loading",
        "partnerstwo":"partnership",
        "zakończony":"completed",
        "trwa":"in progress",
        "w planach":"upcoming",
        "privacyPolicy": "Privacy Policy",
        "Niniejsza polityka wyjaśnia, co robimy z Twoimi danymi osobowymi.":"This policy explains what we do with your personal data.",
        "Zgoda na korzystanie z plików cookie zgodnie z warunkami niniejszej polityki podczas pierwszej wizyty na naszej stronie pozwala nam na korzystanie z plików cookie przy każdej kolejnej wizycie na naszej stronie.":"Consenting to the use of cookies in accordance with the terms of this policy during the first visit to our website enables us to use cookies on every subsequent visit to our website.",
        "Informacje ogólne":"General Information",
        "Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem url: poprostudasie.org":"This policy applies to the website, operating at the URL: poprostudasie.org",
        "Operatorem serwisu oraz Administratorem danych osobowych jest: Fundacja Po Prostu Da Się  z siedzibą przy ul. Waleriana Łukasińskiego 22/7 w Chorzowie.":"The operator of the service and the administrator of personal data is: Fundacja Po Prostu Da Się with its registered office at ul. Waleriana Łukasińskiego 22/7 in Chorzów.",
        "Adres kontaktowy poczty elektronicznej operatora: poprostudasie@gmail.com (Pod tym adresem możesz kontaktować się z Fundacją w sprawach związanych z ochroną danych osobowych oraz szeroko pojętą prywatnością) ":"The operator's email contact address is: poprostudasie@gmail.com (You can use this address to contact the Foundation regarding personal data protection and privacy matters).",
        "Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych podanych dobrowolnie w Serwisie.":"The operator is the administrator of your personal data concerning data voluntarily provided on the website.",
        "Serwis wykorzystuje dane osobowe w następujących celach: prowadzenie newslettera, obsługa zapytań przez formularz,  prezentacja oferty lub informacji.":"The service uses personal data for the following purposes: running a newsletter, handling inquiries via forms, presenting offers or information.",
        "Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w następujący sposób:":"The service collects information about users and their behavior in the following ways:" ,
        "Poprzez dobrowolnie wprowadzone w formularzach lub przesłane do nas dane, które zostają wprowadzone do systemów Operatora (Twoje dane są przetwarzane w celu kontaktu z Tobą, a podstawą przetwarzania jest art. 6 ust. 1 lit. f RODO, czyli prawnie uzasadniony interes)":"Through data voluntarily entered into forms or sent to us, which are entered into the operator's systems (your data is processed for the purpose of contacting you, and the basis for processing is Article 6(1)(f) of the GDPR, which is a legitimate interest).",
        "Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw. „ciasteczka”).":"By saving 'cookies' in end-user devices (so-called 'cookies').",
        "Wybrane metody ochrony danych stosowane przez Operatora":"Selected Data Protection Methods Used by the Operator",
        "Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane logowania, wprowadzone na stronie, zostają zaszyfrowane w komputerze użytkownika i mogą być odczytane jedynie na docelowym serwerze.":"Login and personal data entry locations are secured at the transmission layer (SSL certificate). This ensures that personal data and login information entered on the website are encrypted on the user's computer and can only be read on the target server.",
        "Istotnym elementem ochrony danych jest regularna aktualizacja wszelkiego oprogramowania, wykorzystywanego przez Operatora do przetwarzania danych osobowych, co w szczególności oznacza regularne aktualizacje komponentów programistycznych.":"An essential element of data protection is the regular updating of all software used by the Operator for processing personal data, which includes regular updates to programming components.",
        "Serwis jest hostowany (technicznie utrzymywany) na serwerach operatora: OVH Sp. z o.o. ": "The service is hosted (technically maintained) on the servers of the operator: OVH Sp. z o.o.",
        "Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych":"Your Rights and Additional Information About Data Processing",
        "W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na Administratorze. Dotyczy to takich grup odbiorców:":"In some situations, the Administrator has the right to transfer your personal data to other recipients if it is necessary to perform a contract with you or to fulfill obligations imposed on the Administrator. This applies to such groups of recipients:",
        "firma hostingowa na zasadzie powierzenia, operatorzy pocztowi, operatorzy płatności, upoważnieni pracownicy i współpracownicy, którzy korzystają z danych w celu realizacji celu działania strony":"hosting company on the basis of entrustment, postal operators, payment operators, authorized employees, and associates who use data to achieve the purpose of the site's operation",
        "Twoje dane osobowe przetwarzane przez Administratora nie dłużej, niż jest to konieczne do wykonania związanych z nimi czynności określonych osobnymi przepisami (np. o prowadzeniu rachunkowości). W odniesieniu do danych marketingowych dane nie będą przetwarzane dłużej niż przez 3 lata.":"Your personal data processed by the Administrator will not be stored for longer than is necessary to perform activities related to them specified by separate regulations (e.g., accounting). In the case of marketing data, they will not be processed for more than 3 years.",
        "Przysługuje Ci prawo żądania od Administratora: dostępu do danych osobowych Ciebie dotyczących, ich sprostowania, usunięcia, ograniczenia przetwarzania, oraz przenoszenia danych.":"You have the right to request from the Administrator: access to your personal data, their correction, deletion, processing restriction, and data portability.",
        "Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania wskazanego w pkt 3.3 c) wobec przetwarzania danych osobowych w celu wykonania prawnie uzasadnionych interesów realizowanych przez Administratora, w tym profilowania, przy czym prawo sprzeciwu nie będzie mogło być wykonane w przypadku istnienia ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub obrony roszczeń. Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych Osobowych.":"You have the right to object to the processing referred to in point 3.3 c) concerning the processing of personal data for the purposes of the legitimate interests pursued by the Administrator, including profiling, where the right to object cannot be exercised if there are valid legal grounds for processing, overriding your interests, rights, and freedoms, in particular to establish, assert or defend claims. The President of the Office for Personal Data Protection is the authority to lodge a complaint against the actions of the Administrator.",
        "Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi Serwisu. W stosunku do Ciebie mogą być podejmowane czynności polegające na zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia przez Administratora marketingu bezpośredniego.": "Providing personal data is voluntary but necessary to use the Service. Automated decision-making, including profiling, may be taken against you to provide services under the concluded contract and for the Administrator's direct marketing.",
        "Dane osobowe mogą być przekazywane do państw trzecich w związku z korzystaniem z narzędzi, które przechowują dane osobowe na serwerach zlokalizowanych w państwach trzecich":"Personal data may be transferred to third countries in connection with the use of tools that store personal data on servers located in third countries.",
        "Informacje w formularzach":"Information in Forms",
        "Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane osobowe, o ile zostaną one podane.":"The service collects information voluntarily provided by the user, including personal data if provided.",
        "Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres IP).":"The service may save information about connection parameters (time, IP address).",
        "Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą powiązanie danych w formularzu z adresem e-mail użytkownika wypełniającego formularz. W takim wypadku adres e-mail użytkownika pojawia się wewnątrz adresu url strony zawierającej formularz.":"The service, in some cases, may save information facilitating the connection of data in the form with the email address of the user filling out the form. In this case, the user's email address appears inside the url of the page containing the form.",
        "Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza, np. w celu prowadzonej rekrutacji czy dostarczania informacji mailowych za pośrednictwem newslettera. Każdorazowo kontekst i opis formularza w czytelny sposób informuje, do czego on służy.":"Data provided in the form is processed for the purpose resulting from the function of a specific form, e.g. for recruitment or providing email information through the newsletter. Each time the context and description of the form clearly inform what it is used for.",
        "Logi Administratora":"Administrator's Logs",
        "Informacje zachowaniu użytkowników w serwisie mogą podlegać logowaniu. Dane te są wykorzystywane w celu administrowania serwisem.":"Information about users' behavior on the website may be subject to logging. This data is used to administer the service.",
        "Istotne techniki marketingowe":"Important Marketing Techniques",
        "Operator stosuje analizę statystyczną ruchu na stronie, poprzez Google Analytics (Google Inc. z siedzibą w USA). Operator nie przekazuje do operatora tej usługi danych osobowych, a jedynie zanonimizowane informacje. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu końcowym użytkownika. W zakresie informacji o preferencjach użytkownika gromadzonych przez sieć reklamową Google użytkownik może przeglądać i edytować informacje wynikające z plików cookies przy pomocy narzędzia: https://www.google.com/ads/preferences/":"The operator uses statistical analysis of website traffic through Google Analytics (Google Inc. based in the USA). The operator does not transmit personal data to the service provider, only anonymized information. The service is based on the use of cookies in the end device of the user. In terms of information about user preferences collected by the Google advertising network, the user can view and edit information derived from cookies using the tool: https://www.google.com/ads/preferences/",
        "Operator stosuje korzysta z piksela Facebooka. Ta technologia powoduje, że serwis Facebook (Facebook Inc. z siedzibą w USA) wie, że dana osoba w nim zarejestrowana korzysta z Serwisu. Bazuje w tym wypadku na danych, wobec których sam jest administratorem, Operator nie przekazuje od siebie żadnych dodatkowych danych osobowych serwisowi Facebook. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu końcowym użytkownika.":"The operator uses the Facebook pixel. This technology allows the Facebook service (Facebook Inc. based in the USA) to know that a person registered on it uses the Service. In this case, it is based on data for which the operator is the administrator himself, and the operator does not provide any additional personal data to the Facebook service. The service is based on the use of cookies in the end device of the user.",
        "Informacja o plikach cookies":"Cookie Information",
        "Serwis korzysta z plików cookies.','Pliki cookies (tzw. 'ciasteczka') stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.','Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.','Pliki cookies wykorzystywane są w następujących celach: utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki której użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła; realizacji celów określonych powyżej w części 'Istotne techniki marketingowe';":"The service uses cookies.','Cookies are computer data, especially text files that are stored in the end device of the Service User and are intended to use the websites of the Service. Cookies typically contain the name of the website they come from, the time they are stored on the end device, and a unique number.'The entity placing cookies on the end device of the Service User and accessing them is the operator of the Service.Cookies are used for the following purposes: maintaining the user's session of the Service (after logging in), thanks to which the user does not have to re-enter the login and password on each subpage of the Service; achieving the purposes specified above in the 'Important Marketing Techniques' section;",
        "W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.":"The Service uses two basic types of cookies: 'session' cookies and 'permanent' cookies. 'Session' cookies are temporary files that are stored in the User's end device until logging out, leaving the website or turning off the software (web browser). 'Permanent' cookies are stored in the User's end device for the time specified in the parameters of cookies or until they are deleted by the User.",
        "Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne blokowanie plików cookies Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.":"The web browsing software (web browser) usually allows cookies to be stored in the User's end device by default. Service Users can change their settings in this area. The web browser allows you to delete cookies. It is also possible to automatically block cookies Detailed information on this topic can be found in the help or documentation of the web browser.",
        "Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu wykorzystywane mogą być również przez współpracujące z operatorem Serwisu podmioty, w szczególności dotyczy to firm: Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA), Twitter (Twitter Inc. z siedzibą w USA).":"Cookies placed in the end device of the Service User may also be used by entities cooperating with the operator of the Service, in particular this applies to companies: Google (Google Inc. based in the USA), Facebook (Facebook Inc. based in the USA), Twitter (Twitter Inc. based in the USA).",
        "Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.":"Restrictions on the use of cookies may affect some of the functionalities available on the Service's web pages.",
        "Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www.":"If the user does not want to receive cookies, they can change their browser settings. We reserve that disabling cookies necessary for the processes of user authentication, security, maintaining user preferences may make it difficult, and in extreme cases may prevent the use of websites.",
        "Poniżej podajemy przykładowe sposoby blokowania plików cookies w wybranych przeglądarkach:":"Below are example methods of blocking cookies in selected browsers:",
        "w przeglądarce Internet Explorer (wersja 10) można blokować pliki cookie, korzystając z dostępnych ustawień zastępowania obsługi plików cookie, klikając 'Narzędzia', 'Opcje internetowe', 'Prywatność', a następnie 'Zaawansowane'; ":"In Internet Explorer (version 10), you can block cookies by using the available cookie handling settings. Click on 'Tools,' 'Internet Options,' 'Privacy,' and then 'Advanced.'",
        "w przeglądarce Firefox (wersja 24) możesz zablokować wszystkie pliki cookie, klikając 'Narzędzia', 'Opcje', 'Prywatność', wybierając 'Użyj ustawień niestandardowych dla historii' z menu rozwijanego i odznaczając 'Akceptuj ciasteczka z witryn'":"In Firefox (version 24), you can block all cookies by clicking on 'Tools,' 'Options,' 'Privacy,' selecting 'Use custom settings for history' from the dropdown menu, and unchecking 'Accept cookies from sites.'",
        "w Chrome (wersja 29) możesz zablokować wszystkie pliki cookie, otwierając menu 'Dostosuj i kontroluj', klikając 'Ustawienia', 'Pokaż ustawienia zaawansowane' i 'Ustawienia treści', a następnie wybierając 'Blokuj witrynom ustawianie dowolnych danych' pod nagłówkiem 'Pliki cookie'.":"In Chrome (version 29), you can block all cookies by opening the 'Customize and Control' menu, clicking on 'Settings,' 'Show advanced settings,' and 'Content settings.' Then, choose 'Block third-party cookies and site data' under the 'Cookies' header.",
        "Zablokowanie wszystkich plików cookie będzie miało negatywny wpływ na możliwość korzystania z wielu stron internetowych. Jeśli zablokujesz pliki cookie, nie będziesz mógł korzystać z wszystkich funkcji naszej strony. Możesz usunąć pliki cookie już zapisane na komputerze — na przykład: w przeglądarce Internet Explorer (wersja 10) należy ręcznie usunąć pliki cookie (instrukcje można znaleźć na stronie http://support.microsoft.com/kb/278835 );":"Blocking all cookies may have a negative impact on your ability to use many websites. If you block cookies, you won't be able to access all the features of our website. You can also delete already stored cookies on your computer. For example, in Internet Explorer (version 10), you need to manually delete cookies (instructions can be found at http://support.microsoft.com/kb/278835);",
        "w przeglądarce Firefox (wersja 24) możesz usunąć pliki cookie, klikając 'Narzędzia', 'Opcje' i 'Prywatność', a następnie wybierając 'Użyj ustawień niestandardowych dla historii', klikając 'Pokaż pliki cookie', a następnie klikając 'Usuń wszystkie pliki cookie'; i w przeglądarce Chrome (wersja 29) można usunąć wszystkie pliki cookie, otwierając menu 'Dostosuj i kontroluj', klikając 'Ustawienia', 'Pokaż ustawienia zaawansowane' i 'Wyczyść dane przeglądarki', a następnie wybierając 'Usuń pliki cookie i dane innych stron i wtyczek' przed kliknięciem 'Wyczyść dane przeglądania'.":"in Firefox (version 24), you can delete cookies by clicking on 'Tools,' 'Options,' and 'Privacy,' and then selecting 'Use custom settings for history,' clicking 'Show Cookies,' and finally 'Remove All Cookies'; and in Chrome (version 29), you can delete all cookies by opening the 'Customize and Control' menu, clicking 'Settings,' 'Show advanced settings,' and 'Clear browsing data,' and then selecting 'Delete cookies and other site and plug-in data' before clicking 'Clear browsing data.'",
        "Darowizny":"Donations",
        "1. Darowiznę można przekazać po kliknięciu „wpłać darowiznę” na stronie poprostudasie.org.pl/wesprzyj. Po kliknięciu w link należy postępować zgodnie z instrukcjami zamieszczonymi na stronie operatora płatności.": "1. A donation can be made by clicking 'Donate' on the website poprostudasie.org.pl/wesprzyj. After clicking the link, please follow the instructions provided on the payment service provider's website.",
        "2. Przekazywanie darowizn na stronie fundacji odbywa się za pośrednictwem operatora płatności – spółka PayU SA z siedzibą w Poznaniu, ul. Grunwaldzka 186, 60-166 Poznań, NIP 7792308495, REGON 300523444, KRS 0000274399.":"2. Donations on the foundation's website are processed through the payment service provider - PayU SA, located at ul. Grunwaldzka 186, 60-166 Poznań, with Tax Identification Number (NIP) 7792308495, National Business Registry Number (REGON) 300523444, and National Court Register (KRS) number 0000274399.",
        "3. Darowiznę można wpłacić przy pomocy przelewu elektronicznego, karty płatniczej (kredytowej lub debetowej) i systemu płatności mobilnych BLIK.":"3. Donations can be made using electronic bank transfers, credit or debit cards, and mobile payment systems such as BLIK.",
        "4. Wpłaty Darczyńców są chronione przy pomocy bezpiecznego certyfikatu SSL. Dzięki niemu wszystkie przesyłane informacje są zaszyfrowane.":"4. Donor payments are secured with a safe SSL certificate. This ensures that all transmitted information is encrypted.",
        "5. Za pośrednictwem strony można przekazywać darowizny jednorazowe we wskazanej lub dowolnie ustalonej kwocie, lub darowizny cykliczne umożliwiające regularne, comiesięczne przekazywanie zadeklarowanej kwoty pieniężnej na rzecz Fundacji.":"5. Through the website, you can make one-time donations in a specified or freely chosen amount, as well as recurring donations allowing for regular monthly contributions to the Foundation.",
        "6. Darczyńca wybierając przekazanie darowizny w formie płatności cyklicznej decyduje się na comiesięczne pobieranie przez Operatora Płatności (PayU S.A) z karty płatniczej kwoty pieniężnej odpowiadającej wysokości wybranej lub wpisanej przez siebie Darowizny.":"6. When a donor chooses to make a recurring payment, they authorize the monthly deduction of the chosen or self-entered donation amount from their credit card by the Payment Operator (PayU S.A).",
        "7. Darowizna cykliczna będzie pobierana przez Operatora płatności raz w miesiącu.":"Recurring donations will be processed by the Payment Operator once a month.",
        "8. Darczyńca w ramach usługi płatności cyklicznych ma możliwość zapisania danych karty i zlecenia stałego polecania zapłaty. Dane karty są przechowywane przez operatora płatności (PayU S.A.). PayU pośrednicząc w dokonaniu płatności, udostępnia Token do płatności cyklicznych (wirtualny identyfikator karty), umożliwiający przypisanie do indywidualnego Darczyńcy unikalnego identyfikatora, za pomocą którego Darczyńca cyklicznie dokonuje płatności na rzecz Fundacji.":"8. Within the recurring payment service, the donor has the option to save card data and set up a standing payment order. Card data is stored by the payment operator (PayU S.A.). PayU facilitates recurring payments by providing a Token for recurring payments (a virtual card identifier), enabling the assignment of a unique identifier to each individual donor. With this identifier, donors can make regular payments to the Foundation.",
        "9. Płatność cykliczna zostaje przedłużona automatycznie na koniec każdego okresu subskrypcji. Oznacza to automatyczne pobieranie z karty płatniczej wskazanej przez Darczyńcę kwoty darowizny.":"9. Recurring payments are automatically extended at the end of each subscription period. This means that the chosen donation amount is automatically charged to the donor's credit card.",
        "10. Fundacja nie gromadzi i nie przetwarza w swoim systemie informatycznym danych z kart płatniczych (kredytowych lub debetowych) Darczyńców. Dane te Darczyńca udostępnia wyłącznie Operatorowi płatności.":"10. The Foundation neither stores nor processes credit or debit card data in its IT system. This data is exclusively provided by the donor to the Payment Operator.",
        "11. PayU nie pobiera od Darczyńcy opłaty od realizacji usługi. Cała kwota darowizny trafia na konto Fundacji. Operator pobiera prowizję, określoną w regulaminie usługi, bezpośrednio od Fundacji.":"11. PayU does not charge the donor for the service. The entire donation amount goes to the Foundation. The operator deducts a commission, specified in the service regulations, directly from the Foundation.",
        "12. Darczyńca może złożyć reklamację, jeżeli Usługi przewidziane w regulaminach operatora płatności nie zostały zrealizowane lub są realizowane niezgodnie z jego postanowieniami. Reklamację można złożyć w formie:":"12. The donor may file a complaint if the services specified in the payment operator's regulations have not been provided or if they have been provided in a manner inconsistent with their provisions. A complaint can be submitted in the form of:",
        "a. pisemnej na adres PayU S.A., ul. Grunwaldzka 186, 60-166 Poznań":"a. In writing to the address of PayU S.A., ul. Grunwaldzka 186, 60-166 Poznań.",
        "b. elektronicznej poprzez formularz https://www.payu.pl/pomoc)":"b. remotely via the form at https://www.payu.pl/pomoc).",
        "c. telefonicznej pod numerem telefonu: + 48 61 628 45 05 (połączenie płatne wg taryfy operatora, z którego usług korzysta Darczyńca), od poniedziałku do piątku w godzinach od 8:00 do 20:00.":"c. by phone at the following number: +48 61 628 45 05 (call charges according to the operator's tariff used by the Donor), from Monday to Friday between 8:00 and 20:00.",
        "13. Szczegóły dotyczące realizacji płatności online określają regulaminy Operatora płatności dostępne na stronie: https://www.payu.pl/pliki-do-pobrania.":"13. Details regarding the execution of online payments are defined in the payment service provider's terms and conditions available on the website: https://www.payu.pl/pliki-do-pobrania",
        "14. W sprawach nieuregulowanych niniejszym regulaminem stosuje się odpowiednie obowiązujące przepisy prawne.":"14. In matters not covered by this regulation, the relevant legal provisions shall apply.",
  //     "maj-wrzesień":"May to September",
  //      "dorośli":"adults",
  //      "szkolenie":"training",
  //      "wymiana młodzieżowa":"Youth exchange",
  //      "Organizacje pozarządowe, dorośli, młodzież":"Non-governmental organizations, adults, youth",
  //     "Partnerstwo na małą skalę":"Small-scale partnership",







        donationForm: {
          firstNamePlaceholder: "First Name",
          lastNamePlaceholder: "Last Name",
          emailPlaceholder: "Email Address",
          amountPlaceholder: "Amount",
          agreementRequired: "Agreement is required",
          requiredField: "This field cannot be empty",
          invalidAmount: "Amount must be a number.",
          minAmount: "Minimum amount is 1 PLN.",
          invalidAmountFormat: "Invalid amount format.",
          invalidEmail: "Invalid email address",
          maxCharactersFirstName: "Maximum character limit is 20.",
          maxCharactersLastName: "Maximum character limit is 30.",
          submitButton: "Donate",
        },

        navDropdown: {
          name: "About Us", // Tłumaczenie dla "name" w komponencie NavDropDown
          zespol: "Our Team", // Tłumaczenie dla "zespół" w komponencie NavDropDown
          downloadError: "Cannot load the list.", // Tłumaczenie dla "Pobieranie listy nie powiodło się."
          loading: "Loading...", // Tłumaczenie dla "Wczytywanie listy..."
        },
      },
    },
    pl: {
      translation: {
        "co robimy?": "Co robimy?",
        współpraca: "Współpraca",
        "o nas": "O nas",
        "nasze projekty": "Nasze projekty",
        kontakt: "Kontakt",
        "monster2.text":
          "Prowadzimy kreatywne szkolenia dla młodzieży i dorosłych.",
        "monster2.paragraph":
          "Uczymy otwartości, równego traktowania i rozwijania swoich umiejętności na warsztatach outdoroowych i stacjonarnych. W niekonwencjonalny sposób integrujemy grupy (pracowników/uczniów/nieznajomych). Organizujemy zaangażowane wydarzenia artystyczne.",
        "monster2.button": "Dołącz do nas",
        "recruitmentSlider.noActiveRecruitmentMessage": "AKTUALNIE NIE TRWAJĄ ŻADNE REKRUTACJE, ZOBACZ CO JESZCZE MOŻEMY DLA CIEBIE ZROBIĆ.",
        "header.text": "Otwieramy się na różnorodność.",
        "header.button": "Dowiedz się więcej",

        "aboutUsSection.title": "Zespół",

        "actions.cooperation": "Współpraca",
        "actions.cooperationDescription": "Sprawdź co możemy dla Ciebie zrobić",
        "actions.support": "Wesprzyj",
        "actionImage.alt": "Strzałka akcji",

        "contact.title": "Kontakt",

        "cookiesPopup.agree": "Zgadzam się",
        "cookiesPopup.disagree": "Nie zgadzam się",

        "donationButton.support": "Wesprzyj",

        "footer.logoAlt": "Logo Fundacji Po Prostu Da Się",
        "footer.facebookIconAlt": "Ikona Facebooka",
        "footer.instagramIconAlt": "Ikona Instagrama",
        "footer.youtubeIconAlt": "Nasz kanał na YouTube",
        "footer.privacyPolicyLink": "Polityka prywatności",
        "footer.creatorsText":
          "© {{year}} Wszystkie prawa zastrzeżone. Strona internetowa zaprojektowana we współpracy z Magdą Bremer i Mateuszem Łuczkowskim. Realizacja Mateusz Łuczkowski.",

        "newsletter.title": "Bądź na bieżąco!",
        "newsletter.subtitle": "Zostań poinformowany o naszych działaniach",
        "newsletter.sendingMessage":
          "Trwa wysyłanie formularza. Proszę czekać...",

        "newsletterForm.namePlaceholder": "Imię",
        "newsletterForm.emailPlaceholder": "E-mail",
        "newsletterForm.agreementText":
          "Wyrażam zgodę na otrzymywanie drogą elektroniczną na wskazany przeze mnie adres email informacji handlowej w rozumieniu art. 10 ust. 1 ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną od Fundacji pod nazwą FUNDACJA PO PROSTU DA SIĘ",
        "newsletterForm.emailRequired": "To pole nie może być puste",
        "newsletterForm.invalidEmail": "Nieprawidłowy adres e-mail",
        "newsletterForm.nameRequired": "To pole nie może być puste",
        "newsletterForm.agreementRequired":
          "Zgoda na przetwarzanie twoich danych jest obowiązkowa",
        "newsletterForm.recaptchaRequired": "Potwierdź, że nie jesteś robotem",
        "newsletterForm.submitButton": "Zapisz się",

        "newsletterPostMessage.successTitle": "Dziękujemy!",
        "newsletterPostMessage.errorTitle": "Ups! Coś poszło nie tak.",
        "newsletterPostMessage.emailExistTitle": "Ups! Coś poszło nie tak.",
        "newsletterPostMessage.successParagraph":
          "Twój mail został pomyślnie dopisany do listy użytkowników subskrybujących nasz newsletter.",
        "newsletterPostMessage.errorParagraph":
          "Odśwież stronę i spróbuj jeszcze raz.",
        "newsletterPostMessage.emailExistParagraph":
          "Jesteś już naszym aktywnym subskrybentem.",

        "timelineCard.exampleEventTitle": "Przykładowe wydarzenie",
        "timelineCard.exampleEventDate": "1 stycznia 2023",
        "timelineCard.exampleEventDescription": "Przykład opisu.",
        "timelineCard.clickToLearnMore": "Kliknij aby dowiedzieć się więcej",

        "timelineSection.whatWeDo": "Czym się zajmujemy?",
        "timelineSection.loadingData": "Trwa wczytywanie danych...",
        "timelineSection.loadingError":
          "Ups... coś poszło nie tak. Błąd wczytywania danych z serwera.",

        "banner.title": "Weź udział",
        "banner.whiteTriangleAlt": "biały trójkąt",
        "banner.subtitle":
          "Poniżej znajdziesz aktualnie prowadzone przez nas rekrutacje",

        "values.title": "Wartości",
        "contentData.header":
          "W naszej pracy najważniejsze są inkluzywność, otwartość, antyschematyzm oraz dobro człowieka i środowiska. Dążymy do włączania w działania wszystkich osób, bez względu na płeć, pochodzenie, wygląd, stopień sprawności, wiek, orientację seksualną, czy inne cechy tożsamościowe. Odkrywamy nowe sposoby na rozwiązywanie zaobserwowanych przez nas problemów społecznych. Działamy kreatywnie, z poszanowaniem środowiska naturalnego. Dążymy do rozpowszechnienia wiedzy o prawach człowieka oraz ich przestrzegania.",

        expand: "rozwiń",
        collapse: "zwiń",

        "person.0.description": "1asdasdas",

        "coop.title": "Współpraca",
        "services.title": "Co możemy dla Ciebie zrobić?",
        
        "sliderSection.title": "Z kim współpracujemy?",
        "sliderSection.errorMessage": "Ups! Coś poszło nie tak.",
        "sliderSection.loadingMessage": "Trwa wczytywanie...",
        "recruitmentSlider.loadingMessage":"Trwa wczytywanie",
        "noActiveRecruitmentMessage":"Aktualnie nie trwają żadne rekrutacje, zobacz co jeszcze możemy dla Ciebie zrobić.",
        "icons.type": "Typ",
        "icons.forWho": "Dla kogo",
        "icons.duration": "Minimalny czas trwania",

        "bankTransferInfo.title": "Dane do przelewu",
        "bankTransferInfo.bankName": "SANTANDER BANK POLSKA SA",
        "bankTransferInfo.bankAccount": "02 1090 2688 0000 0001 4416 6731",
        "bankTransferInfo.bankTitle": "W TYTULE: DAROWIZNA NA CELE STATUTOWE",
        "bankTransferInfo.foundationName": "FUNDACJA PO PROSTU DA SIĘ",
        "bankTransferInfo.foundationAddress":
          "UL. WALERIANA ŁUKASIŃSKIEGO 22/7",
        "bankTransferInfo.foundationCity": "41-506 CHORZÓW",

        "donationAgreement.agreementText":
          "W związku z dokonywaną przeze mnie darowizną, zgadzam się na przetwarzanie moich danych osobowych przez Fundację Po Prostu Da Się z siedzibą przy ul. Waleriana Łukasińskiego 22/7 w Chorzowie.",
        "donationAgreement.agreementText2":
          "Więcej o zasadach przetwarzania danych osobowych przez Fundację możesz dowiedzieć się w naszej",
        "donationAgreement.privacyPolicyLink": "polityce prywatności.",

        "donationPage.statusTitle": "Status płatności",
        "donationPage.donateTitle": "Wpłać datek",
        "donationPage.loadingMessage": "Ładowanie...",

        "amountButton.currency": "PLN",
        "donationNav.bankTransfer": "Przelew Tradycyjny",
        "privacyPolicy": "Polityka Prywatności",


        donationForm: {
          firstNamePlaceholder: "Imię",
          lastNamePlaceholder: "Nazwisko",
          emailPlaceholder: "Adres e-mail",
          amountPlaceholder: "Kwota",
          agreementRequired: "Wyrażenie zgody jest obowiązkowe",
          requiredField: "To pole nie może być puste",
          invalidAmount: "Kwota musi być liczbą.",
          minAmount: "Minimalna kwota to 1zł.",
          invalidAmountFormat: "Nieprawidłowy format kwoty.",
          invalidEmail: "Nieprawidłowy adres e-mail",
          maxCharactersFirstName: "Maksymalna liczba znaków to 20.",
          maxCharactersLastName: "Maksymalna liczba znaków to 30.",
          submitButton: "Wpłać darowiznę",
        },


        navDropdown: {
          name: "O Nas", // Tłumaczenie dla "name" w komponencie NavDropDown
          zespol: "Zespół", // Tłumaczenie dla "zespół" w komponencie NavDropDown
          downloadError: "Pobieranie listy nie powiodło się.", // Tłumaczenie dla "Pobieranie listy nie powiodło się."
          loading: "Wczytywanie listy...", // Tłumaczenie dla "Wczytywanie listy..."
        },
      },
    },
  },
  lng: "pl", // Domyślny język
  fallbackLng: "pl", // Język domyślny w razie braku tłumaczenia
  interpolation: {
    escapeValue: false, // Zabezpieczenie przed XSS
  },
});

export default i18n;
