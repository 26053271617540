import styled from "styled-components";

export const Root = styled.section`
   grid-area: contact;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: 4rem;
   color: #444;
   margin: 0;
`;
