import { createSlice, current } from "@reduxjs/toolkit";
import { theme, darkTheme, yDarkTheme } from "utils";
export const themeSlice = createSlice({
   name: "theme",
   initialState: {
      currentTheme: theme,
      currentThemeName: "light" as switchPayloadType,
      fontSize: 0 as fontSizeType,
   },
   reducers: {
      switchTheme: (state, { payload }: switchAction) => {
         switch (payload) {
            case "dark":
               if (current(state).currentThemeName === payload) {
                  state.currentTheme = theme;
                  state.currentThemeName = "light";
               } else {
                  state.currentThemeName = payload;
                  state.currentTheme = darkTheme;
               }
               break;

            case "darkYellow":
               if (current(state).currentThemeName === payload) {
                  state.currentTheme = theme;
                  state.currentThemeName = "light";
               } else {
                  state.currentThemeName = payload;
                  state.currentTheme = yDarkTheme;
               }
               break;
            default:
               state.currentTheme = theme;
         }
      },
      toggleFontSize: (state) => {
         current(state).fontSize >= 3
            ? (state.fontSize = 0)
            : (state.fontSize = state.fontSize + 1);
      },
   },
});

export const { toggleFontSize, switchTheme } = themeSlice.actions;
export type switchPayloadType = "dark" | "darkYellow" | "light";
export type fontSizeType = number;
type switchAction = { payload: switchPayloadType };
// type fontSizeAction = { payload: fontSizeType };
export type ThemeState = {
   theme: ReturnType<typeof themeSlice.getInitialState>;
};
export default themeSlice.reducer;
