import styled from "styled-components";

const Root = styled.div<rootProps>`
   display: flex;
   align-items: center;
   justify-content: flex-end;
   position: relative;
   top: 0;
   right: 0;
   width: 100%;
   font-size: 2rem;
   transition: ${({ theme: { transition } }) => transition};
   z-index: 1000;
   ${({ isDisplayed }) =>
      isDisplayed
         ? `
         height: 5rem;
         overflow: visible;
         opacity: 1;
         border-bottom: 0.1rem dashed #ccc;`
         : `
         height: 0rem;
         overflow: hidden;
         opacity: 0;
         border-bottom: none;`};
`;

const Flag = styled.div<optionProps>`
   margin: 1rem;
   height: 3rem;
   width: 3rem;
   border-radius: 0.8rem;
   background: url(${({ background }) => background});
   background-position: center;
   background-size: cover;
   border: 0.1rem solid #aaa;
   transition: ${({ theme: { transition } }) => transition};
`;
const FlagContainer = styled.div<flagPickerProps>`
   display: flex;
   flex-direction: column;
   list-style: none;
   positon: relative;
   top: 0;
   display: flex;
   align-items: center;
   cursor: pointer;
   height: 5rem;
   border-radius: 1rem 1rem 0 0;
   transition: ${({ theme: { transition } }) => transition};
   background-color: ${({ theme: { colors }, langIsOpen }) =>
      langIsOpen ? colors.red : ""};
   ${({ theme: { colors }, langIsOpen }) =>
      langIsOpen
         ? `& div div {
         border: 0.1rem solid #333;
      };
      & div {
         color:  ${colors.white};
      }
      `
         : ""};
`;
const CurrentFlag = styled.div`
   display: flex;
   align-items: center;
   padding: 0 1rem;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      padding: 0 0.4rem;
   }
`;
const FlagPicker = styled.ul<flagPickerProps>`
   margin: 0;
   list-style: none;
   width: 100%;
   transition: ${({ theme: { transition } }) => transition};
   ${({ theme: { colors }, langIsOpen }) =>
      langIsOpen
         ? `
   opacity: 1;
   pointer-events:  "auto";
   background-color: ${colors.red};
   border-radius: 0 0 1rem 1rem;
   `
         : `
         pointer-events:"none";
         opacity: 0;
         `}
   & li div {
      border: none;
   }
`;
const FlagOption = styled.li`
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0.4rem;
   transition: ${({ theme: { transition } }) => transition};
   &:hover {
      background-color: ${({ theme: { colors } }) => colors.green};
   }
   &:last-of-type {
      border-radius: 0 0 1rem 1rem;
   }
`;

const ThemeSwitcher = styled.div`
   margin: 0 3rem;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      margin: 0 0.5rem;
   }
`;
const ThemeSwitcherButton = styled.button<ThemeSwitcherProps>`
   margin: 0 0.4rem;
   padding: 0.4rem;
   width: 3.5rem;
   font-size: 1.8rem;
   font-weight: bold;
   background-color: transparent;
   border: 0.1rem solid black;
   border-radius: 0.8rem;
   cursor: pointer;
   ${({ isColored }) =>
      isColored
         ? "color: black !important; background-color: white !important; border:1px solid black !important;"
         : ""};
`;

type rootProps = { isDisplayed: boolean };
type optionProps = { background: string };
type flagPickerProps = { langIsOpen: boolean };
type ThemeSwitcherProps = { isColored?: boolean };

export {
   CurrentFlag,
   Flag,
   FlagContainer,
   FlagOption,
   FlagPicker,
   Root,
   ThemeSwitcher,
   ThemeSwitcherButton,
};
