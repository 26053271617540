import { useState } from "react";
import { useTranslation } from "react-i18next"; // Importujemy funkcję useTranslation do obsługi tłumaczeń

import { Root, Subtitle, Title } from "./Newsletter.css";
import {
   LoadingIndicator,
   NewsletterForm,
   NewsletterPostMessage,
} from "components";

export default function Newsletter() {
   const { t } = useTranslation(); // Inicjalizacja hooka do tłumaczeń

   const [postStatus, setPostStatus] = useState<postStatusT>("none");

   return (
      <Root>
         <Title children={t("newsletter.title")} />
         <Subtitle children={t("newsletter.subtitle")} />
         <NewsletterForm
            postStatus={postStatus}
            setPostStatus={setPostStatus}
         />
         <NewsletterPostMessage postStatus={postStatus} />
         {postStatus === "pending" && (
            <LoadingIndicator message={t("newsletter.sendingMessage")} />
         )}
      </Root>
   );
}

export type postStatusT =
   | "none"
   | "pending"
   | "success"
   | "error"
   | "email exist";
