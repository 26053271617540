import styled from "styled-components";

export const Root = styled.section`
   display: flex;
   flex-direction: column;
   align-items: center;
   max-width: 100vw;
`;
export const Title = styled.h2`
   margin-bottom: 10rem;
   text-align: center;
   text-transform: uppercase;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      margin-bottom: 6rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.xlg}) {
      font-size: 5rem;
      padding: 0 2rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      font-size: 3rem;
      letter-spacing: 0;
      padding: 1rem;
   }
`;

export const IconsWrapper = styled.div`
   display: flex;
   width: 70%;
   justify-content: space-around;
`;

export const Icon = styled.img`
   max-height: 4rem;
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      max-height: 3rem;
   }
`;

export const Paragraph = styled.p`
   margin-top: 8rem;
   width: 70%;
   font-size: 2.6rem;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      width: 80%;
      margin-top: 6rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      width: 90%;
      font-size: 1.8rem;
   }
`;
