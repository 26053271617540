import { createSlice } from "@reduxjs/toolkit";

export const windowSlice = createSlice({
   name: "window",
   initialState: {
      isMobile: true,
      isCookiesAccepted: false,
   },
   reducers: {
      setValue: (state, { payload }: setNewValue) => {
         const { value, type } = payload;
         switch (type) {
            case "isMobile":
               state.isMobile = value;
               break;
            case "isCookiesAccepted":
               state.isCookiesAccepted = value;
         }
      },
      setInitialValues: (state, { payload }: setNewInitialValues) => {
         state = payload;
      },
   },
});

export const { setValue, setInitialValues } = windowSlice.actions;

type setNewValue = {
   payload: {
      value: boolean;
      type: "isMobile"  | "isCookiesAccepted";
   };
};
type setNewInitialValues = {
   payload: {
      isMobile: boolean;
      isCookiesAccepted: boolean;
   };
};
export type WindowState = {
   window: ReturnType<typeof windowSlice.getInitialState>;
};
export default windowSlice.reducer;
