import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, PersonName, Root, PersonText, Portrait } from "./Person.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { WindowState } from "features/reducers/window.reducer";
import { useTranslation } from "react-i18next";

export default function Person({ person }: personProps) {
  const [isTrimed, setIsTrimed] = useState(true);
  const { id, name, description, photo } = person;
  const { isMobile } = useSelector(({ window }: WindowState) => window);

  const { t, i18n } = useTranslation();

  const handleDescription = () => {
    if (i18n.language === "pl" && description.pl) {
      return description.pl;
    } else if (i18n.language === "en" && description.en) {
      return description.en;
    }
    return ""; // Dodaj obsługę innych języków lub błędów
  };

  const handleClick = () => setIsTrimed(!isTrimed);

  return (
    <Root>
      <Portrait src={photo} alt={name} />
      <PersonName children={name} />
      <PersonText
        className={`${id === 0 ? "first" : ""}`}
        isTrimed={isMobile && isTrimed}
      >
        {handleDescription()} {/* Wywołaj funkcję do obsługi opisu */}
        {isTrimed && (
          <Button onClick={handleClick}>
            {`${isTrimed ? t("expand") : t("collapse")}`}
            <FontAwesomeIcon icon={faAnglesDown} />
          </Button>
        )}
      </PersonText>
    </Root>
  );
}

type personProps = {
  person: {
    id: number;
    name: string;
    description: {
      pl: string;
      en: string;
    };
    photo: string;
  };
};
