import styled from "styled-components";

export const Root = styled.div`
   display: flex;
   padding: 2rem 10rem;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: fixed;
   bottom: 0rem;
   left: 50%;
   transform: translateX(-50%);
   width: 100%;
   background-color: ${({ theme: { colors } }) => colors.white};
   z-index: 1000;
   box-shadow: 2rem 2rem 7rem rgba(0, 0, 0, 0.7);
   max-width: 100vw;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      left: 0;
      transform: translateX(0);
      padding: 2rem 5rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      padding: 1rem;
   }
`;
export const Paragraph = styled.p`
   margin: 1.2rem 0;
   font-size: 1.8rem;
   a {
      font-size: 1.8rem;
      color: ${({ theme: { colors } }) => colors.blue};
      @media (max-width: ${({ theme: { media } }) => media.sm}) {
         font-size: 1.4rem;
      }
      @media (max-width: ${({ theme: { media } }) => media.tablet}) {
         font-size: 1.1rem;
      }
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      font-size: 1.4rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      margin: 1rem;
      font-size: 1.1rem;
   }
`;
export const Navigation = styled.div`
   align-self: flex-start;
   display: flex;
   justify-content: space-between;
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      width: 100%;
   }
`;
export const NavigationButton = styled.button`
   margin: 1rem 0rem;
   font-size: 1.6rem;
   color: ${({ theme: { colors } }) => colors.blue};
   font-weight: bold;
   border: 0.2rem ${({ theme: { colors } }) => colors.blue} solid;
   border-radius: 2rem;
   padding: 1rem 2rem;
   background-color: transparent;
   cursor: pointer;
   transition: ${({ theme: { transition } }) => transition};

   &:nth-of-type(2) {
      margin-left: 9rem;
      opacity: 0.7;
      color: ${({ theme: { colors } }) => colors.darkGray};
      font-weight: normal;
      border: none;
      @media (max-width: ${({ theme: { media } }) => media.sm}) {
         margin-left: 0rem;
      }
   }
   @media (min-width: ${({ theme: { media } }) => media.md}) {
      &:first-of-type:hover {
         background-color: ${({ theme: { colors } }) => colors.blue};
         color: ${({ theme: { colors } }) => colors.white};
      }
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      font-size: 1.4rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      margin: 0rem 1rem;
      font-size: 1.2rem;
   }
`;
export const Wrapper = styled.div``;
