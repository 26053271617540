import styled from "styled-components";

export const Root = styled.div<rootProps>`
   margin: 0 auto;
   width: 120rem;
   display: grid;
   grid-template-columns: 100%;
   justify-content: center;
   grid-template-rows:  ${({marginTop})=>marginTop}px repeat(9, auto);
   grid-template-areas: "navbar" "header" "monster" "actions" "values" "aboutUs" "timeline" "contact" "slider" "footer";
   @media (max-width: ${({ theme: { media } }) => media.lg}) {
      width: 100%;
   }
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      grid-template-rows: repeat(10, auto);
      max-width: 100vw;
      overflow: hidden;
   }
`;

interface rootProps {marginTop:number}