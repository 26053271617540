import styled from "styled-components";
import { postStatusT } from "components/Newsletter/Newsletter";
export const Root = styled.div<rootProps>`
   margin: 5rem;
   padding: 8rem;
   width: 80%;
   background-color: ${({ theme: { colors }, postStatus }) =>
      postStatus === "success" ? colors.green : "transparent"};
   border: ${({ theme: { colors }, postStatus }) =>
      postStatus !== "success" ? `1px solid ${colors.white}` : "none"};
   border-radius: 1rem;
`;
export const Title = styled.h3`
   font-size: 5.5rem;
   color: ${({ theme: { colors } }) => colors.white};
   width: 100%;
   text-align: center;
   margin-bottom: 3rem;
`;
export const Paragraph = styled.p`
   font-size: 2.8rem;
   width: 100%;
   text-align: center;
`;

interface rootProps {
   postStatus: postStatusT;
}
