import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import { fetchedProjectData, parsedProjectData } from 'interfaces';
import TimelineSection from './TimelineSection';

const getProjectsURL = '/api/projects/get';

function TimelineContainer() {
	const [reloadTimeline, setReloadTimeline] = useState<boolean>(false);

	const { isLoading, error, data, refetch } = useQuery<
		unknown,
		unknown,
		parsedProjectData[]
	>('timelineProjects', () =>
		fetch(getProjectsURL)
			.then((res) => {
				if (res.ok) return res.json();
				throw new Error(res.statusText);
			})
			.then((data) => {
				const parsedData: fetchedProjectData[] = [...data];
				const parseDate = (date: string) => new Date(date);
				parsedData.forEach(
					(
						{
							startDate,
							endDate,
							startRecruitmentDate,
							endRecruitmentDate,
						},
						index,
						dataCopy
					) => {
						if (typeof startDate === 'string')
							dataCopy[index].startDate = parseDate(startDate);
						if (typeof endDate === 'string')
							dataCopy[index].endDate = parseDate(endDate);
						if (typeof startRecruitmentDate === 'string')
							dataCopy[index].startRecruitmentDate =
								parseDate(startRecruitmentDate);
						if (typeof endRecruitmentDate === 'string')
							dataCopy[index].endRecruitmentDate =
								parseDate(endRecruitmentDate);
					}
				);
				return parsedData;
			})
	);

	const [activeYear, setActiveYear] = useState<string>(() => {
		const currentDate = new Date();
		return `${currentDate.getFullYear()}`;
	});

	const avaiableYearData = data
		? ((data: parsedProjectData[]) => {
				const years: string[] = [];
				data.forEach(({ startDate }) =>
					years.includes(`${startDate.getFullYear()}`)
						? null
						: years.push(`${startDate.getFullYear()}`)
				);
				return years.sort((a, b) => Number(b) - Number(a));
		  })(data)
		: [`${new Date().getFullYear()}`];

	const handleClick = (
		year: string,
		contentWrapperElement: React.RefObject<HTMLElement>
	) => {
		if (contentWrapperElement.current)
			contentWrapperElement.current.scrollTop = 0;
		return setActiveYear(year);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				await refetch();
				setReloadTimeline(false);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [activeYear, refetch, reloadTimeline]);

	const filteredYearData =
		activeYear !== null && data
			? data.filter(
					({ startDate }) =>
						`${startDate.getFullYear()}` === activeYear
			  )
			: null;

	return (
		<TimelineSection
			key={reloadTimeline.toString()}
			activeYear={activeYear}
			avaiableYearData={avaiableYearData}
			handleClick={handleClick}
			filteredYearData={filteredYearData}
			isLoading={isLoading}
			error={error ? (error as string) : ''}
		/>
	);
}

export default TimelineContainer;
