import { useEffect, useRef } from "react";
import { Button, MonsterText, Root } from "./Header.css";
import { useSelector } from "react-redux";
import { NavbarState } from "features/reducers/navbar.reducer";
import { Element, Link as LinkScroller } from "react-scroll";
import { useDispatch } from "react-redux";
import { setOffset } from "features/reducers/navbar.reducer";
import { WindowState } from "features/reducers/window.reducer";
import { useTranslation } from "react-i18next"; // Importuj useTranslation

export default function Header() {
   const dispatch = useDispatch();
   const { isMobile } = useSelector(({ window }: WindowState) => window);
   const rootElement = useRef(null);
   const navbarOffsetHeight = useSelector(
      ({ navbar }: NavbarState) => navbar.offsets.navbarOffsetHeight
   );

   // Użycie useTranslation
   const { t } = useTranslation();

   useEffect(() => {
      if (rootElement.current) {
         const refElement = rootElement.current as HTMLElement;
         const offsetTop = refElement.offsetTop;
         dispatch(setOffset({ type: "headerOffset", value: offsetTop }));
      }
   });

   return (
      <Element name="header" style={{ gridArea: "header" }}>
         <Root ref={rootElement}>
            <MonsterText children={t("header.text")} /> {/* Zaktualizuj treść tekstu używając tłumaczenia */}
            <LinkScroller
               to="monster2"
               duration={1000}
               smooth={true}
               offset={isMobile ? 0 : -navbarOffsetHeight}
            >
               <Button children={t("header.button")} /> {/* Zaktualizuj treść przycisku używając tłumaczenia */}
            </LinkScroller>
         </Root>
      </Element>
   );
}
