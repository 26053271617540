import React from "react";
import { Element, Link as LinkScroller } from "react-scroll";
import { useSelector } from "react-redux";
import { NavbarState } from "features/reducers/navbar.reducer";
import { Button, MonsterText, Paragraph, Root } from "./Monster.css";
import { WindowState } from "features/reducers/window.reducer";
import { useTranslation } from "react-i18next"; // Import useTranslation

export default function Monster2() {
  const { navbarOffsetHeight } = useSelector(
    ({ navbar }: NavbarState) => navbar.offsets
  );
  const { isMobile } = useSelector(({ window }: WindowState) => window);

  // Użycie useTranslation
  const { t } = useTranslation();

  return (
    <Element name="monster2" style={{ gridArea: "monster" }}>
      <Root>
        <MonsterText children={t("monster2.text")} />
        <Paragraph children={t("monster2.paragraph")} />
        <LinkScroller
          to="actions"
          duration={1000}
          smooth={true}
          offset={isMobile ? 0 : -navbarOffsetHeight}
        >
          <Button children={t("monster2.button")} />
        </LinkScroller>
      </Root>
    </Element>
  );
}
