import { useEffect } from "react";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

export default function ScrollToTop({ children }: scrollToTop) {
   const [searchParams] = useSearchParams();
   const scrollParam = searchParams.get("scroll");
   const location = useLocation();
   useEffect(() => {
      if (!scrollParam) {
         window.scrollTo(0, 0);
      }
   }, [location, scrollParam]);

   return <>{children}</>;
}

type scrollToTop = {
   children: React.ReactNode;
};
