import { Element } from "react-scroll";
import contentData from "server/aboutUsText";
import {
  Icon,
  IconsWrapper,
  Paragraph,
  Root,
  Title,
} from "./ValuesSection.css";

import bird1 from "assets/img/bird1.webp";
import bird2 from "assets/img/bird2.webp";
import bird3 from "assets/img/bird3.webp";
import { useTranslation } from "react-i18next"; // Importuj useTranslation tutaj

export default function ValuesSection() {
  const { t } = useTranslation(); // Wywołaj useTranslation wewnątrz komponentu

  return (
    <Element name="values" style={{ gridArea: "values" }}>
      <Root>
        <Title children={t("values.title")} />
        <IconsWrapper>
          <Icon src={bird1} alt="ikona ptaka" />
          <Icon src={bird2} alt="ikona ptaka" />
          <Icon src={bird3} alt="ikona ptaka" />
        </IconsWrapper>
        <Paragraph children={t("contentData.header")} />
      </Root>
    </Element>
  );
}
