import styled from "styled-components";
import ReactCountryFlag from "react-country-flag";



export const FlagIconWrapper = styled.div`
  border: 0px;
  border-radius: 15px;
  width: 7em;
  height: 5em;
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
`;

export const FlagIcon = styled(ReactCountryFlag)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const LanguageButton = styled.button`
  padding: 0.2rem 0.2rem;
  font-size: 2.6rem;
  color: ${({ theme: { colors } }) => colors.gray};
  background-color: ${({ theme: { colors } }) => colors.gray};
  border-radius: 1rem;
  border: none;
  cursor: pointer;

  @media (min-width: ${({ theme: { media } }) => media.md}) {
    transition: ${({ theme: { transition } }) => transition};
    &:hover {
      transform: scale(1.2);
      background-color: ${({ theme: { colors } }) => colors.white};
      color: ${({ theme: { colors } }) => colors.red};

      }
    }
  }
`;
