import styled from "styled-components";

const Root = styled.div`
   display: flex;
   position: relative;
   flex-direction: column;
   align-items: center;
   height: 45rem;
   width: 32rem;
   margin: 2rem;
   background-color: ${({ theme: { colors } }) => colors.red};
   overflow: hidden;
   border-radius: 2rem;
   cursor: pointer;
   transition: ${({ theme: { transition } }) => transition};
   @media (min-width: ${({ theme: { media } }) => media.tablet}) {
      &:hover {
         &:first-of-type {
            box-shadow: 0 0 2rem rgba(0, 0, 0, 0.7);
            div {
               height: 100%;
               h3 {
                  background-color: rgba(0, 0, 0, 0.7);
                  letter-spacing: 0.4rem;
               }
            }
            & p {
               opacity: 1;
            }
         }
      }
   }

   @media (max-width: ${({ theme: { media } }) => media.xlg}) {
      width: 28rem;
      height: 42rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      width: 30rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.mobile}) {
   }
`;
const Title = styled.h3`
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 5rem 1rem;
   margin-bottom: 2rem;
   height: 100%;
   max-width: 100%;
   color: white;
   background-color: rgba(0, 0, 0, 0.45);
   text-align: center;
   font-size: 2.8rem;
   letter-spacing: 0.2rem;
   transition: ${({ theme: { transition } }) => transition};
   @media (max-width: ${({ theme: { media } }) => media.xlg}) {
      font-size: 2.6rem;
   }
`;
const Description = styled.p`
   position: absolute;
   left: 0;
   bottom: 1rem;
   height: 50%;
   width: 100%;
   display: flex;
   align-items: center;
   padding: 0 3rem;
   font-size: 2.6rem;
   overflow: hidden;
   text-align: center;
   opacity: 0;
   transition: ${({ theme: { transition } }) => transition};
`;
const Status = styled.p`
   width: 10rem;
   position: absolute;
   font-weight: bold;
   padding: 0.2rem 0.8rem;
   top: 0;
   left: 0;
   font-size: 1.8rem;
   text-align: center;
   background-color: ${({ theme: { colors } }) => colors.red};
`;
const Date = styled.p`
   position: absolute;
   padding: 0.2rem 0.5rem;
   width: 50%;
   top: 0;
   right: 0;
   text-align: center;
   font-weight: bold;
   font-size: 1.8rem;
   background-color: ${({ theme: { colors } }) => colors.red};
`;
const Image = styled.div<image>`
   height: 50%;
   width: 100%;
   background: url(${({ background }) => background});
   background-position: center;
   background-size: cover;
   transition: ${({ theme: { transition } }) => transition};
`;

const List = styled.ul`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   list-style: none;
`;
const ListElement = styled.li`
   display: flex;
   align-items: center;
   margin-bottom: 2rem;
   width: 50%;
`;
const Icon = styled.img`
   width: 3rem;
`;
const Text = styled.p`
   font-size: 1.8rem;
   flex-grow: 1;
   margin-left: 2rem;
`;

type image = { background: string };

export {
   List,
   ListElement,
   Icon,
   Text,
   Root,
   Title,
   Description,
   Status,
   Date,
   Image,
};
