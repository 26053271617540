import {
   Icon,
   Paragraph,
   ParagraphsWrapper,
   Root,
   SocialIcon,
   Title,
   Triangle,
 } from "./ContactListElement.css";
 
 import instagramIcon from "assets/img/icon_instagram.webp";
 import facebookIcon from "assets/img/icon_facebook.webp";
 
 import { oneContactElement } from "server/contact";
 import redTriangle from "assets/img/trojkat-czerwony.webp";
 import { useTranslation } from "react-i18next";
 
 export default function ContactListElement({
   listElement,
 }: contactListElementProps) {
   const { t } = useTranslation(); // Initialize t for translations
 
   return (
      <Root>
        <Title children={t(listElement.title.pl)} /> 
        <Triangle src={redTriangle} alt="czerwony trójkąt" />
        <ParagraphsWrapper>
          {" "}
          {listElement.paragraphs !== undefined &&
            listElement.paragraphs.map((paragraph) => (
              <Paragraph children={t(paragraph)} key={paragraph} />
            ))}
          {listElement.socialMedia !== undefined &&
            listElement.socialMedia.map((link, index) => (
              <SocialIcon
                target="_blank"
                href={link}
                key={link}
                rel="noopener noreferrer"
              >
                {
                  <Icon
                    src={index === 0 ? facebookIcon : instagramIcon}
                    alt={`link do ${
                      index === 0 ? "facebooka" : "instagrama"
                    }  fundacji`}
                  />
                }
                {index === 0 ? "facebook" : "instagram"}
              </SocialIcon>
            ))}
        </ParagraphsWrapper>
      </Root>
   );
 }
 
 type contactListElementProps = {
   listElement: oneContactElement;
 };
 