import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ScrollToTop } from "components";
import { store } from "store";
import { Provider } from "react-redux";
import App from "./App";
import i18n from "../src/components/i18n/i18n"; // Importuj instancję i18n

const queryClient = new QueryClient();

ReactDOM.render(
   <QueryClientProvider client={queryClient}>
      <Provider store={store}>
         <Router>
            <ScrollToTop>
               <App />
            </ScrollToTop>
         </Router>
      </Provider>
   </QueryClientProvider>,
   document.getElementById("root")
);
