import photo from "assets/img/coop/sample_coop_photo.webp";
import intensywneWarsztaty from "assets/img/coop/intensywne_warsztaty.webp";
import ruszWBusz from "assets/img/coop/rusz_w_busz_2.webp";
import partnerstwo from "assets/img/coop/partnerstwo.webp";

const services: service[] = [
  {
    id: 0,
    title: {
      pl: "Esencja",
      en: " ",
    },
    textContent: {
      pl: "Warsztaty skrojone na miarę. Podczas intensywnych kilku godzin popracujemy nad wybranym zagadnieniem (np. podejściem równościowym, integracją grupy, rozbudzeniem kreatywności, komunikacją interpersonalną w środowisku międzykulturowym, odkryciem indywidualnego potencjału). Możemy w interaktywny sposób popracować nad komunikacją bez przemocy (nvc), poruszyć takie tematy jak TUS (trening umiejętności społecznych), team building czy przeprowadzić trening antydyskryminacyjny.",
      en: "",
    },

    photo: photo,
    forWho: {
      pl: "5 - 20 osób",
      en: "",
    },

    type: {
      pl: "szkolenie stacjonarne",
      en: "",
    },
    duration: {
      pl: "2h",
      en: "2h",
    },
  },
  {
    id: 1,
    title: {
      pl: "Intensywne warsztaty",
      en: "",
    },
    textContent: {
      pl: "Spotkajmy się we wskazanym przez Ciebie miejscu i popracujmy kreatywnie nad tematem, który wspólnie wybierzemy. Zapomnij o nudnym wykładzie i siedzeniu w miejscu - do każdego zagadnienia przygotowujemy aktywizujące ćwiczenia  i na bieżąco reagujemy na potrzeby grupy. W czasie warsztatu wykorzystujemy autorski dobór metod, czerpiących m.in. z improwizacji scenicznej, mindfulness czy edukacji osób dorosłych (non formal education). Od Ciebie zależy czy wolisz jeden, intensywny, kreatywny dzień czy chcesz spotykać się z nami cyklicznie.",
      en: "",
    },

    photo: photo,
    forWho: {
      pl: "5 - 20 osób",
      en: "",
    },

    type: {
      pl: "szkolenie stacjonarne",
      en: "",
    },
    duration: {
      pl: "3-8h",
      en: "3-8h",
    },
  },

  {
    id: 2,
    title: {
      pl: "Rusz w Busz",
      en: "",
    },
    textContent: {
      pl: "Niezapomniane doświadzczenie outdoroowe. To coś więcej niż integracja czy szkolenie. Rusz w Busz to zielona droga z mentorem, która pomoże wydobyć wasze mocne strony. Podczas wyjazdu certyfikowane instruktorki i mentorki przeprowadzą kompleksowe warsztaty rozwijające wybrane kompetencje miękkie i przeciwdziałające wypaleniu zawodowemu. Szkolenie wyjazdowe daje możliwości pogłębionej pracy zarówno nad indywidualnymi celami jak i poprawia dynamikę funkcjonowania w grupie.",
      en: "",
    },

    photo: photo,
    forWho: {
      pl: "5 - 20 osób",
      en: "",
    },

    type: {
      pl: "szkolenie wyjazdowe",
      en: "",
    },
    duration: {
      pl: "3-7 dni",
      en: "3-7 days",
    },
  },

  {
   id: 3,
   title: {
     pl: "Partnerstwo",
     en: "",
   },
   textContent: {
     pl: "Jako organizacja pozarządowa mamy duże doświadczenie w przygotowywaniu (w tym pisaniu wniosków), organizowaniu i prowadzeniu różnorodnych projektów. Nasze główne obszary działania to: prawa człowieka w tym prawa kobiet i mniejszości, równe traktowanie, edukacja artystyczna. Mówimy po polsku i angielsku. Chętnie podzielimy się naszą wiedzą i zasobami, żeby wspólnie tworzyć nową jakość - napiszmy wspólnie wniosek, przygotujmy wydarzenie, poprowadźmy warsztaty. Możemy nawiązać współpracę długoterminową lub dotyczącą konkretnego projektu/ przedsięwzięcia.",
     en: "",
   },

   photo: photo,
   forWho: {
     pl: "ngo/instytucja kultury",
     en: "",
   },

   type: {
     pl: "partnerstwo",
     en: "",
   },
   duration: {
     pl: "3-7 dni",
     en: "3-7 days",
   },
 },

];

export const headerText = [
 
  "Chętnie dzielimy się naszym doświadczeniem i pokazujemy w praktyce jak prowadzić działania równościowe i antydyskryminacyjne.",
  "Możesz zaprosić nas do swojej firmy, szkoły, organizacji czy instytucji. A może chcesz żebyśmy zorganizowały dla Ciebie i Twojego zespołu warsztaty wyjazdowe, nieszablonowe wydarzenie integracyjne lub przygotowały dedykowany cykl szkoleń?",
  "Mamy też duże doświadczenie w prowadzeniu i koordynowaniu projektów - chętnie wspólnie poszukamy możliwości realizacji pomysłów. Jesteśmy otwarte na współpracę lokalną, ponadregionalną i międzynarodową.",

];
export interface service {
  id: number;
  title: {
    pl: string;
    en: string;
  };
  textContent: {
    pl: string;
    en: string;
  };
  photo: string;
  forWho: {
    pl: string;
    en: string;
  };
  type: {
    pl: string;
    en: string;
  };
  duration?: {
    pl: string;
    en: string;
  };
}
export default services;
