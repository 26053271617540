import { Button } from "./DonationButton.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Dodajemy hook do tłumaczenia

export default function DonationButton() {
   const { t } = useTranslation(); // Inicjalizacja hooka do tłumaczenia

   return (
      <Link to="/wesprzyj">
         <Button children={t("donationButton.support")} /> {/* Używamy tłumaczenia */}
      </Link>
   );
}
