import styled from "styled-components";

const Root = styled.div`
   margin-bottom: 5rem;
   font-size: 2.1rem;
   form {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      input {
         padding: 1rem 2rem;
         margin: 2.5rem;
         font-size: 2.3rem;
         border: none;
         border-radius: 1.5rem;
         &#agreement {
            padding: 0;
            width: 2.3rem;
            height: 2.3rem;
            background-color: white;
            border-radius: 0.7rem;
            vertical-align: middle;
            border: none;
            appearance: none;
            outline: none;
            cursor: pointer;
            &:checked {
               background-color: ${({ theme: { colors } }) => colors.blue};
            }
         }
         &::placeholder {
            text-transform: uppercase;
            text-align: center;
            color: red;
         }
      }
   }
`;

const Label = styled.label`
   margin: 3rem;
   flex-basis: 80%;
   display: flex;
   align-items: flex-start;
   justify-content: center;
   cursor: pointer;
`;
const Paragraph = styled.p`
   font-size: 1.8rem;
   max-width: 70%;
`;
const SubmitButton = styled.button`
   padding: 1rem 2rem;
   margin: 3rem 0;
   background-color: ${({ theme: { colors } }) => colors.green};
   color: white;
   font-size: 2.8rem;
   border: none;
   border-radius: 0.5rem;
   cursor: pointer;
   &:disabled {
      opacity: 0.7;
      pointer-events: none;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      width: 80%;
      padding: 1.5rem;
   }
`;

const InputWrapper = styled.div`
   position: relative;
   margin-bottom: 3rem;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      margin-bottom: 3rem;
   }
`;

const InputsWrapper = styled.div`
   display: flex;
   width: 100%;
   justify-content: center;
   @media (max-width: ${({ theme: { media } }) => media.sm}) {
      flex-direction: column;
      align-items: center;
   }
`;

const ErrorWrapper = styled.div<errorWrapperProps>`
   display: "flex";
   align-items: center;
   justify-content: center;
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
   top: 90%;
   padding: 0.5rem 2rem;
   min-width: 80%;
   min-height: 3rem;
   text-align: center;
   border-radius: 1rem;
   background-color: ${({ theme: { colors } }) => colors.green};
   color: ${({ theme: { colors } }) => colors.white};
   font-size: 1.8rem;
   transition: ${({ theme: { transition } }) => transition};
   opacity: ${({ isDisplayed }) => (isDisplayed ? "1" : "0")};
`;

type errorWrapperProps = {
   isDisplayed: boolean;
};

export {
   ErrorWrapper,
   InputWrapper,
   InputsWrapper,
   Root,
   Label,
   Paragraph,
   SubmitButton,
};
