import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useLocation, Link } from "react-router-dom";
import {
   NavbarState,
   setActiveElement,
} from "features/reducers/navbar.reducer";
import { WindowState } from "features/reducers/window.reducer";
import { scroller } from "react-scroll";
import {
   NavButton,
   Dropdown,
   DropdownElement,
   DropdownLink,
} from "./Navbar.css";

import { useTranslation } from 'react-i18next';

const getAllURL = "/api/files/get";

export default function NavDropDown({
   name,
   scrollTo,
   parentIsOpen,
   isOpened,
   setIsOpened,
}: dropdownProps) {
   const {
      activeElement,
      offsets: { navbarOffsetHeight },
   } = useSelector(({ navbar }: NavbarState) => navbar);
   const { isMobile } = useSelector(({ window }: WindowState) => window);
   const { t } = useTranslation();
   const { isLoading, error, data } = useQuery<unknown, unknown, fetchedData>(
      "pdfFiles",
      () =>
         fetch(getAllURL).then((res) => {
            if (res.ok) return res.json();
            throw new Error(res.statusText);
         })
   );
   const dispatch = useDispatch();
   const location = useLocation();
   const toggleOpen = (value: boolean) => setIsOpened(!value);

   return (
      <NavButton
         key={name}
         onMouseEnter={() => {
            if (isMobile) return;
            toggleOpen(false);
         }}
         onMouseLeave={() => {
            if (isMobile) return;
            toggleOpen(true);
         }}
         onClick={isMobile ? () => toggleOpen(isOpened) : undefined}
         isOpen={parentIsOpen}
         className={activeElement === scrollTo ? "active dropdown" : "dropdown"}
      >
         <p>{t('navDropdown.name')}</p>
         <Dropdown isOpened={isOpened}>
            <Link
               key={name}
               to="/"
               state={{
                  isChangedPath: location.pathname !== `/`,
                  scrollParameter: scrollTo,
               }}
               style={
                  activeElement === scrollTo ? { pointerEvents: "none" } : {}
               }
            >
               <DropdownElement
                  children={t('navDropdown.zespol')}
                  className={activeElement === scrollTo ? "active" : ""}
                  onClick={() => {
                     if (activeElement === scrollTo) return;
                     dispatch(setActiveElement("aboutUs"));

                     if (location.pathname === "/") {
                        scroller.scrollTo(scrollTo, {
                           duration: 1000,
                           smooth: true,
                           offset: -navbarOffsetHeight,
                        });
                     }
                  }}
                  isOpened={isOpened}
               />
            </Link>

            {!isLoading && data && error === null ? (
               data.map(({ name, path }) => (
                  <DropdownLink
                     isOpened={isOpened}
                     children={name}
                     target="blank"
                     rel="noreferrer"
                     href={path}
                     key={path}
                  />
               ))
            ) : (
               <p
                  children={
                     error
                        ? t('navDropdown.downloadError')
                        : t('navDropdown.loading')
                  }
                  style={{ fontSize: "21px", margin: "1rem 0" }}
               />
            )}
         </Dropdown>
      </NavButton>
   );
}

type dropdownProps = {
   name: string;
   scrollTo: string;
   activeNavButton: string;
   parentIsOpen: boolean;
   isOpened: boolean;
   setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
   setParentisOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

type fetchedData = { name: string; path: string; _id: string }[];
