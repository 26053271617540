import styled from "styled-components";

export const Root = styled.div`
   height: 100vh;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`;

export const Form = styled.form`
   font-size: 2rem;
   display: flex;
   flex-direction: column;
   align-items: center;
`;
export const Input = styled.input`
   margin: 0.5rem;
   padding: 0.5rem 2rem;
`;
export const Button = styled.button`
   ${({ theme: { colors, transition } }) => `
   padding: 0.8rem 3rem;
   border: none;
   background-color: ${colors.green};
   color: ${colors.white};
   font-weight: bold;
   cursor: pointer;
   text-align: center;
   transition: ${transition};
   &:hover {
      background: ${colors.red}
   }
`}
`;
export const Title = styled.h3``;
