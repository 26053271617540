import { useEffect, useRef } from "react";
import { Element } from "react-scroll";
import { useDispatch } from "react-redux";

import { setOffset } from "features/reducers/navbar.reducer";
import { Root } from "./ContactSection.css";
import { Contact, Newsletter } from "components";
export default function ContactSection() {
   const dispatch = useDispatch();
   const rootElement = useRef(null);
   useEffect(() => {
      if (rootElement.current) {
         const refElement = rootElement.current as HTMLElement;
         const offsetTop = refElement.offsetTop;
         dispatch(setOffset({ type: "contactOffset", value: offsetTop }));
      }
   });
   return (
      <Element name="contact" style={{ gridArea: "contact" }}>
         <Root ref={rootElement}>
            <Contact />
            <Newsletter />
         </Root>
      </Element>
   );
}
