import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
   NavbarState,
   setActiveElement,
   activeElementType,
} from "features/reducers/navbar.reducer";
import { SubpageHeader } from "components";
import { Footer, SubpageMain, SubpageGrid } from "components";

export default function Subpage({
   activeElementName,
   children,
   headerBg,
   headerText,
   title,
}: subpageProps) {
   const dispatch = useDispatch();
   const {
      activeElement,
      offsets: { navbarOffsetHeight },
   } = useSelector(({ navbar }: NavbarState) => navbar);

   const { t } = useTranslation();

   useEffect(() => {
      if (activeElement === activeElementName) return;
      dispatch(setActiveElement(activeElementName));
   }, [activeElement, activeElementName, dispatch]);

   return (
      <SubpageGrid marginTop={navbarOffsetHeight}>
         <SubpageHeader
            title={t(title)}
            background={headerBg}
            headerText={headerText?.map((text) => t(text))} // Obsługuje null/undefined
         />
         <SubpageMain>{children}</SubpageMain>
         <Footer />
      </SubpageGrid>
   );
}

interface subpageProps {
   children: React.ReactNode;
   title: string;
   headerBg: string;
   activeElementName: activeElementType;
   headerText?: string[];
}
