import styled from "styled-components";

import { ListElementProps } from "interfaces";

export const Root = styled.nav<rootProps>`
   grid-area: navbar;
   top: 0;
   right: 0;
   width: 100%;
   position: fixed;
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
   background-color: ${({ theme: { colors } }) => colors.white};
   color: ${({ theme: { colors } }) => colors.red};
   z-index: 100;
  // border-bottom: 0.1rem dashed #ccc;
   transition: opacity ${({ theme: { transition } }) => transition},
      width ${({ theme: { transition } }) => transition},
      height ${({ theme: { transition } }) => transition} 250ms,
      top ${({ theme: { transition } }) => transition};
   z-index: 1000;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      margin-top: 6rem;
      align-items: flex-start;
      width: ${({ isOpenHamburger }) => (isOpenHamburger ? "45%" : "0rem")};
      height: ${({ isOpenHamburger }) => (isOpenHamburger ? "100vh" : "0rem")};
      left: 0;
      overflow-y: ${({ subHamburgerIsOpened, isOpenHamburger }) =>
         isOpenHamburger && subHamburgerIsOpened ? "scroll" : "visible"};
      border-right: 0.1rem dashed #ccc;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      width: ${({ isOpenHamburger }) => (isOpenHamburger ? "65%" : "7rem")};
   }
`;

export const List = styled.div<hamburgerProps>`
   height: 100%;
   width: 70%;
   padding: 1rem 3rem;
   display: flex;
   align-items: center;
   justify-content: space-around;
   transition: ${({ theme: { transition } }) => transition};
   @media (max-width: ${({ theme: { media } }) => media.xxxlg}) {
      width: 80%;
      align-items: center;
   }
   @media (max-width: ${({ theme: { media } }) => media.xxlg}) {
      width: 90%;
   }
   @media (max-width: ${({ theme: { media } }) => media.xlg}) {
      width: 100%;
   }
   @media (max-width: ${({ theme: { media } }) => media.lg}) {
      padding: 1rem;
   }

   @media (max-width: ${({ theme: { media } }) => media.md}) {
      transform: translateX(
         ${({ isOpenHamburger }) => (isOpenHamburger ? "0" : "-200%")}
      );
      transition-timing-function: linear;
      transition-delay: ${({ isOpenHamburger }) =>
         isOpenHamburger ? "400ms" : "0"};
      padding: 0;
      flex-direction: column;
      height: unset;
      width: 100%;
      overflow: hidden;
      justify-content: space-around;
      min-height: 70vh;
      a:first-child.logo {
         display: none;
      }
   }
`;
export const DonationButtonWrapper = styled.div``;
export const NavButton = styled.button<ListElementProps>`
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 2rem ${({ theme }) => theme.spacing.sm};
   padding: 0;
   height: 5rem;
   color: ${({ theme: { colors } }) => colors.red};
   background-color: transparent;
   font-size: ${({ theme: { font } }) => font.size.largeText};
   transition: ${({ theme: { transition } }) => transition};
   cursor: pointer;
   border: none;
   border-radius: 1.2rem 1.2rem 0 0;
   opacity: 0.8;
   &.dropdown {
      p {
         font-size: inherit;
         transition: transform ${({ theme: { transition } }) => transition};
         cursor: pointer;
      }
      &.active {
         color: ${({ theme: { colors } }) => colors.darkGray};
         font-weight: normal;
         p {
            transform: scale(1.2);
         }
      }
      width: 12rem;
      @media (min-width: 1024px) {
         &:hover {
            background-color: ${({ theme: { colors } }) => colors.red};
            color: ${({ theme: { colors } }) => colors.white};
            &::after {
               width: 0 !important;
            }
         }
      }
   }

   &::after {
      content: "";
      position: absolute;
      bottom: 0.5rem;
      left: 50%;
      transform: translateX(-50%) !important;
      width: 0;
      height: 0.1rem;
      background-color: ${({ theme: { colors } }) => colors.red};
      opacity: 0.6;
      transition: 0.45s;
   }
   @media (min-width: 1024px) {
      &:hover {
         opacity: 1;
         &::after {
            width: 100%;
         }
      }
   }

   &.active {
      cursor: default;
      opacity: 1;
      font-weight: bold;
      &::after {
         display: none;
      }
   }
   @media (max-width: ${({ theme: { media } }) => media.lg}) {
      font-size: 2.6rem;
   }

   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      margin: 0.4rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      background-color: transparent !important;
      color: ${({ theme: { colors } }) => colors.black} !important;
      margin: 1rem 0;
      pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
      cursor: ${({ isOpen }) => (isOpen ? "pointer" : "auto")};
      width: 100%;
      border-radius: 0;
      border-top: 1.5rem solid transparent;
      border-bottom: 1.5rem solid transparent;
      &.last {
         border-radius: ${({ isOpen }) => (isOpen ? "0 0 1rem 1rem" : "0")};
      }
      &.dropdown {
         width: 100%;
         height: auto;
         display: flex;
         flex-direction: column;
      }
      &.active {
         opacity: 0.3;
      }
   }
`;

export const Dropdown = styled.div<dropDownProps>`
   display: flex;
   flex-direction: column;
   position: absolute;
   list-style: none;
   top: 100%;
   left: 50%;
   max-height: ${({ isOpened }) => (isOpened ? "40rem" : "0")};
   width: 100%;
   opacity: ${({ isOpened }) => (isOpened ? "1" : "0")};
   transform: translateX(-50%);
   transition: ${({ theme: { transition } }) => transition};
   overflow: hidden;
   background-color: ${({ theme: { colors } }) => colors.red};
   color: ${({ theme: { colors } }) => colors.white};
   border-radius: 0 0 1.2rem 1.2rem;
   box-shadow: 0 4rem 4rem -2.5rem rgba(0, 0, 0, 0.2);
   z-index: 1;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      color: ${({ theme: { colors } }) => colors.black};
      background-color: transparent;
      position: relative;
      top: 0;
      border-top: 1px dashed ${({ theme: { colors } }) => colors.darkGray};
      border-bottom: 1px dashed ${({ theme: { colors } }) => colors.darkGray};
      border-radius: 0;
      box-shadow: none;
      margin: ${({ isOpened }) => (isOpened ? "2rem auto 0" : "0")};
      a {
         cursor: ${({ isOpened }) => (isOpened ? "auto" : "pointer")};
         pointer-events: ${({ isOpened }) => (isOpened ? "auto" : "none")};
      }
   }
`;
export const DropdownLink = styled.a<dropDownProps>`
   padding: 1rem 0;
   position: relative;
   opacity: 0.8;
   text-align: center;
   background-color: ${({ theme: { colors } }) => colors.red};
   transition: 0.4s;
   color: inherit;
   font-size: 2.1rem;
   text-decoration: inherit;
   @media (min-width: 1024px) {
      &:hover {
         opacity: 1;
         background-color: ${({ theme: { colors } }) => colors.green};
      }
   }

   @media (max-width: ${({ theme: { media } }) => media.md}) {
      opacity: 1;
      background-color: transparent;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      margin: 2rem auto;
      width: 90%;
   }
`;
export const DropdownElement = styled.div<dropDownProps>`
   padding: 1rem;
   position: relative;
   opacity: 0.8;
   font-size: 2.1rem;
   text-align: center;
   background-color: ${({ theme: { colors } }) => colors.red};
   transition: 0.4s;
   color: inherit;
   text-decoration: inherit;
   @media (min-width: 1024px) {
      &:hover {
         opacity: 1;
         background-color: ${({ theme: { colors } }) => colors.green};
      }
   }

   &.active {
      pointer-events: none !important;
      opacity: 1;
      color: ${({ theme: { colors } }) => colors.black};
      background-color: ${({ theme: { colors } }) => colors.green};
   }
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      opacity: 1;
      background-color: transparent !important;
      cursor: ${({ isOpened }) => (isOpened ? "auto" : "pointer")};
      pointer-events: ${({ isOpened }) => (isOpened ? "none" : "auto")};
      &.active {
         pointer-events: none;
      }
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      margin: 2rem auto;
      width: 90%;
   }
`;

export const Logo = styled.img`
   height: 8rem;
   @media (max-width: ${({ theme: { media } }) => media.xxlg}) {
      height: 7rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.xlg}) {
      height: 6rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.lg}) {
      height: 5rem;
   }
`;

export const Hamburger = styled.button`
   display: none;
   width: 100vw;
   background-color: ${({ theme: { colors } }) => colors.white};
   border: none;
   padding: 1rem;
   position: fixed;
   right: 0;
   top: 0;
   z-index: 1;
   cursor: pointer;
   svg {
      position: relative;
      left: 0;
      font-size: 3rem;
      color: ${({ theme: { colors } }) => colors.darkGray};
   }
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      img {
         height: 5rem;
         @media (max-width: ${({ theme: { media } }) => media.tablet}) {
            height: 4.5rem;
         }
         margin: 0 auto;
      }
   }
`;
type rootProps = {
   isOpenHamburger: boolean;
   subHamburgerIsOpened: boolean;
};

type hamburgerProps = { isOpenHamburger: boolean };
type dropDownProps = { isOpened: boolean };
