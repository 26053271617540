import styled from "styled-components";

export const Root = styled.header<headerProps>`
   grid-area: header;
   display: flex;
   align-items: center;
   margin: 0;
   padding: 2rem;
   min-height: 40rem;
   position: relative;
   background: url(${({ background }) => background});
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   @media (max-width: ${({ theme: { media } }) => media.lg}) {
      max-width: 100vw;
   }
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      padding: 0.5rem;
      height: unset;
      flex-direction: column;
      aling-items: center;
      justify-content: center;
   }
`;
type headerProps = { background: string };
export const Title = styled.h1`
   margin: 0 4rem 5rem;
   padding: 4rem 5rem;
   align-self: flex-end;
   display: inline-block;
   font-family: "Champagne&Limousines", sans-serif;
   color: ${({ theme: { colors } }) => colors.white};
   text-transform: uppercase;
   border: 0.6rem solid ${({ theme: { colors } }) => colors.white};
   border-radius: 2rem;
   @media(max-width:${({theme:{media}})=>media.lg}){
      margin: 0 3rem 4rem;
      padding: 3rem 4rem;
      font-size: 4rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      margin: 0;
      width: 100%;
      padding: 2rem;
      font-size: 3rem;
      text-align: center;
      margin: 2rem auto;
      width: 90%;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      width: 90%;
   }
`;
export const IntroductoryText = styled.div`
   margin: 0 4rem;
   position: relative;
   padding: 4rem 5rem;
   width: 100%;
   color: ${({ theme: { colors } }) => colors.white};
   border: 0.3rem solid ${({ theme: { colors } }) => colors.white};
   border-radius: 2rem;
   background-color: rgba(0, 0, 0, 0.2);
   background-color: rgba(55, 135, 114, 0.25);
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      padding: 2rem 4rem;
      margin: 2rem;
      width: 70%;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      width: 90%;
      padding: 2rem;
   }
`;
export const Paragraph = styled.p`
   margin: 1rem 0;
   font-size: 2.1rem;
   font-weight: bold;
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      margin: 0.5rem 0;
      padding: 2rem 0rem;
      font-size: 1.8rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      width: 95%;
      margin: 0 auto;
      padding: 1rem 0;
      text-align: center;
   }
`;
