import styled from "styled-components";

const Root = styled.section`
   margin: 0;
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 10rem 0;
   justify-content: center;
   font-size: 4rem;
   background-color: ${({ theme: { colors } }) => colors.red};
   color: ${({ theme: { colors } }) => colors.white};
   &::after {
      content: "";
      position: absolute;
      width: 100vw;
      height: 100%;
      background: ${({ theme: { colors } }) => colors.red};
      z-index: -1;
   }
`;
const PersonsWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   width: 100%;
   
   @media (max-width: ${({ theme: { media } }) => media.md}) {
      flex-direction: column;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      padding: 0 1rem;
   }
`;
const Title = styled.h2`
   margin-bottom: 4rem;
   text-transform: uppercase;
   color: white;
   @media (max-width: ${({ theme: { media } }) => media.xlg}) {
      font-size: 5rem;
      padding: 0 2rem;
   }
   @media (max-width: ${({ theme: { media } }) => media.tablet}) {
      font-size: 3rem;
      letter-spacing: 0;
      padding: 1rem;
   }
`;

export { PersonsWrapper, Root, Title };
