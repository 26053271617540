import { useFormik } from "formik";
import Cookies from "js-cookie";
import { Button, Form, Input, Root, Title } from "./AdminLogin.css";
const AdminLogin = () => {
   const formik = useFormik({
      initialValues: {
         login: "",
         password: "",
      },
      onSubmit: (values) => {
         fetch("/login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values, null, 2),
         })
            .then((res) => {
               if (res.status === 200) {
                  Cookies.set("logged", "true", { expires: 0.0416 });
                  window.location.href = "admin";
               } else window.location.href = "login";
            })
            .catch((err) => console.error(err));
      },
   });
   return (
      <Root>
         <Form onSubmit={formik.handleSubmit}>
            <Title children={"Panel administratora"} />
            <Input
               id="login"
               name="login"
               onChange={formik.handleChange}
               value={formik.values.login}
               placeholder="login"
            />
            <Input
               id="password"
               name="password"
               type="password"
               onChange={formik.handleChange}
               value={formik.values.password}
               placeholder="hasło"
            />
            <Button type="submit" children="Zaloguj" />
         </Form>
      </Root>
   );
};
export default AdminLogin;
