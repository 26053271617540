import styled, { keyframes } from "styled-components";

const animation = keyframes`
0% {
   transform: rotate(0deg);
}
50% {
   transform: rotate(180deg);
}
100% {
   transform: rotate(360deg);
}
`;
export const Root = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
`;
export const Wrapper = styled.div`
   width: 191px;
   height: 191px;
   display: inline-block;
   overflow: hidden;
   background: none;
`;
export const IndicatorWrapper = styled.div`
   width: 100%;
   height: 100%;
   position: relative;
   transform: translateZ(0) scale(1);
   backface-visibility: hidden;
   transform-origin: 0 0;
`;
export const Indicator = styled.div`
   margin: 0 auto;
   position: absolute;
   animation: ${animation} 1s linear infinite;
   width: 156.62px;
   height: 156.62px;
   top: 17.19px;
   left: 17.19px;
   border-radius: 50%;
   box-shadow: 0 5.348px 0 0 ${({ theme: { colors } }) => colors.blue};
   transform-origin: 78.31px 80.984px;
   box-sizing: content-box;

   /* generated by https://loading.io/ */
`;

export const Message = styled.p`
   font-weight: bold;
   font-size: 2.1rem;
   margin: 4rem;
`;
