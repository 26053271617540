import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActiveElement } from "features/reducers/navbar.reducer";
import { parsedProjectData } from "interfaces";
import {
   List,
   ListElement,
   Icon,
   Text,
   Root,
   Title,
   Description,
   Status,
   Date,
   Image,
} from "./TimelineCard.css";

import iconHuman from "assets/img/icon_human.webp";
import iconTree from "assets/img/icon_tree.webp";
import iconHouse from "assets/img/icon_house.webp";
import { getMonthAndYear } from "features/features";

function TimelineCard({ project }: TimeLineCardProps) {
   const {
      _id,
      title,
      status,
      startDate,
      endDate,
      photo,
      forWho,
      projectType,
      place,
   } = project;

   const dispatch = useDispatch();

   const handleClick = () => {
      dispatch(setActiveElement("none"));
   };

   return (
      <Link to={`/project?id=${_id}`} onClick={handleClick}>
         <Root>
            <Image background={photo}>
               <Date>
                  {`${getMonthAndYear(startDate)}${
                     endDate ? ` - ${getMonthAndYear(endDate)}` : ""
                  }`}
               </Date>
               <Status>{status}</Status>
               <Title>{title}</Title>

               <List>
                  <ListElement>
                     <Icon src={iconHuman} alt="ikona człowieka" />
                     <Text>{forWho}</Text>
                  </ListElement>
                  <ListElement>
                     <Icon src={iconTree} alt="ikona drzewa" />
                     <Text>{projectType}</Text>
                  </ListElement>
                  <ListElement>
                     <Icon src={iconHouse} alt="ikona domu" />
                     <Text>{place}</Text>
                  </ListElement>
               </List>
            </Image>
            <Description>Kliknij, aby dowiedzieć się więcej</Description>
         </Root>
      </Link>
   );
}

type TimeLineCardProps = {
   project: parsedProjectData;
};

export default TimelineCard;
