import React, { useEffect, useRef } from "react";
import { Element } from "react-scroll";
import { useDispatch } from "react-redux";
import { setOffset } from "features/reducers/navbar.reducer";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { parsedProjectData } from "interfaces";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { TimelineCard, SwipeToFirst } from "components";
import {
   Button,
   ButtonWrapper,
   ButtonsContainer,
   CardsWrapper,
   ContentWrapper,
   Root,
   Title,
   TimeLine,
} from "./TimelineSection.css";
import LoadingIndicator from "components/LoadingIndicator";

export default function TimelineSection({
   avaiableYearData,
   activeYear,
   isLoading,
   error,
   handleClick,
   filteredYearData,
}: TimelineSectionProps) {
   const dispatch = useDispatch();
   const rootElement = useRef(null);
   const contentWrapperElement = useRef(null);

   useEffect(() => {
      const handleResize = () => {
         if (rootElement.current) {
            const refElement = rootElement.current as HTMLElement;
            const offsetTop = refElement.offsetTop;
            dispatch(setOffset({ type: "timelineOffset", value: offsetTop }));
         }
      };

      window.addEventListener("resize", handleResize);

      return () => {
         window.removeEventListener("resize", handleResize);
      };
   }, [dispatch]);

   const { t } = useTranslation();

   return (
      <Element name="timeline" style={{ gridArea: "timeline" }}>
         <Root ref={rootElement}>
            <Title children={t("timelineSection.whatWeDo")} />
   
            <TimeLine>
               <ButtonsContainer>
                  <Swiper slidesPerView={"auto"} freeMode={true}>
                     {avaiableYearData.map((year) => (
                        <SwiperSlide key={year}>
                           <ButtonWrapper
                              key={year}
                              className={year === activeYear ? "active" : ""}
                           >
                              <Button
                                 className={year === activeYear ? "active" : ""}
                                 children={year}
                                 onClick={() =>
                                    handleClick(year, contentWrapperElement)
                                 }
                              />
                           </ButtonWrapper>
                        </SwiperSlide>
                     ))}
                  </Swiper>
               </ButtonsContainer>
               <ContentWrapper ref={contentWrapperElement}>
                  {!isLoading && error === "" ? (
                     <CardsWrapper>
                        <Swiper
                           key={filteredYearData ? filteredYearData.length : "loading"}
                           slidesPerView={"auto"}
                           modules={[Navigation, Pagination, A11y]}
                           navigation={true}
                           pagination={{ clickable: true }}
                           centeredSlidesBounds={true}
                        >
                           <SwipeToFirst activeYear={activeYear} />
                           {filteredYearData !== null
                              ? filteredYearData.map((project) => (
                                   <SwiperSlide key={project._id}>
                                      <TimelineCard
                                         key={uuidv4()}
                                         project={project}
                                      />
                                   </SwiperSlide>
                                ))
                              : null}
                        </Swiper>
                     </CardsWrapper>
                  ) : (
                     <LoadingIndicator
                        message={
                           !error
                              ? t("timelineSection.loadingData")
                              : t("timelineSection.loadingError")
                        }
                     />
                  )}
               </ContentWrapper>
            </TimeLine>
         </Root>
      </Element>
   );
}

type TimelineSectionProps = {
   activeYear: string;
   handleClick: (
      year: string,
      contentWrapperElement: React.RefObject<HTMLElement>
   ) => void;
   isLoading: boolean;
   error: string;
   avaiableYearData: string[];
   filteredYearData: parsedProjectData[] | null;
};
