const cookiesPopup =
   'Ta strona używa "ciasteczek" czyli niezbędnych własnych plików cookies, które zapewniają jej prawidłowe działanie oraz plików cookies od podmiotów trzecich. W każdym momencie możesz zmienić ustawienia przeglądarki internetowej i wyłączyć opcję zapisu plików cookies. Ze szczegółowymi informacjami dotyczącymi cookies na tej stronie możesz przeczytać ';


   // 'Ta strona używa "ciasteczek" czyli niezbędnych własnych plików cookies, które zapewniają jej prawidłowe działanie oraz plików cookies od podmiotów trzecich, do których dostęp mają dostawcy narzędzi zewnętrznych (pozwalających m.in. na korzystanie z zewnętrznych narzędzi takich jak Google Analytics, reCaptcha, Facebook). W każdym momencie możesz zmienić ustawienia przeglądarki internetowej i wyłączyć opcję zapisu plików cookies. Ze szczegółowymi informacjami dotyczącymi cookies na tej stronie możesz przeczytać ';



export const cookieQuestion =
   "Czy wyrażasz zgodę na przechowywanie informacji oraz uzyskiwanie dostępu do informacji przechowywanych w plikach cookies?";
export const linkText = "w naszej polityce prywatności";
export default cookiesPopup;
