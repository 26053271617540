import React, { useEffect, Suspense, lazy, useState, useCallback } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Cookie from 'js-cookie';

import { setValue } from 'features/reducers/window.reducer';
import 'normalize.css';
import {
	AdminLogin,
	CookiesPopup,
	Head,
	LoadingIndicator,
	Navbar,
	TopScroller,
} from 'components';
import { GlobalStyle } from 'utils';
import { ThemeState } from 'features/reducers/theme.reducer';

import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from '../src/components/i18n/i18n';

type LanguageSwitcherProps = {
	selectedLanguage: string;
	onChangeLanguage: (newLanguage: string) => void;
};

function LanguageSwitcher({
	selectedLanguage,
	onChangeLanguage,
}: LanguageSwitcherProps) {
	const { t, i18n } = useTranslation();

	const changeLanguage = (newLanguage: string) => {
		onChangeLanguage(newLanguage);
		i18n.changeLanguage(newLanguage);
	};

	return (
		<div>
			<button onClick={() => changeLanguage('pl')}>{t('pl')}</button>
			<button onClick={() => changeLanguage('en')}>{t('en')}</button>
		</div>
	);
}

const Cooperation = lazy(() => import('pages/CooperationPage/CooperationPage'));
const HomePage = lazy(() => import('pages/HomePage/HomePage'));
const DonationPage = lazy(() => import('pages/DonationPage/DonationPage'));
const ProjectPage = lazy(() => import('pages/ProjectPage/ProjectPage'));
const PrivacyPolicyPage = lazy(
	() => import('pages/PrivacyPolicyPage/PrivacyPolicyPage')
);

function AppContainer() {
	const location = useLocation();
	const dispatch = useDispatch();
	const { currentThemeName, currentTheme, fontSize } = useSelector(
		({ theme }: ThemeState) => theme
	);

	const getIsMobile = useCallback(
		() =>
			dispatch(
				setValue({
					value: window.innerWidth < 1024,
					type: 'isMobile',
				})
			),
		[dispatch]
	);

	const getIsCookiesAccepted = useCallback(
		() =>
			dispatch(
				setValue({
					value: Cookie.get('cookiesAgreement') === 'true',
					type: 'isCookiesAccepted',
				})
			),
		[dispatch]
	);

	useEffect(() => {
		getIsMobile();
		getIsCookiesAccepted();

		const handleResize = () => {
			getIsMobile();
		};

		window.addEventListener('resize', handleResize);
		return function cleanup() {
			window.removeEventListener('resize', handleResize);
		};
	}, [getIsCookiesAccepted, getIsMobile]);

	const [selectedLanguage, setSelectedLanguage] = useState('pl');

	const changeLanguage = (newLanguage: string) => {
		setSelectedLanguage(newLanguage);
	};

	return (
		<I18nextProvider i18n={i18n}>
			<ThemeProvider theme={currentTheme}>
				<GlobalStyle fontSize={fontSize} themeName={currentThemeName} />
				<Head />
				<Navbar />
				<LanguageSwitcher
					selectedLanguage={selectedLanguage}
					onChangeLanguage={changeLanguage}
				/>
				<TopScroller />
				<TransitionGroup exit={false}>
					<CSSTransition
						key={location.pathname}
						classNames="fade-in"
						timeout={200}
						unmountOnExit={true}
					>
						<Suspense
							fallback={
								<LoadingIndicator message="Trwa ładowanie strony" />
							}
						>
							<Routes>
								<Route
									path="/wspolpraca"
									element={<Cooperation />}
								/>
								<Route
									path="/wesprzyj"
									element={<DonationPage />}
								/>
								<Route path="/login" element={<AdminLogin />} />
								<Route
									path="/project"
									element={<ProjectPage />}
								/>
								<Route
									path="/privacy_policy"
									element={<PrivacyPolicyPage />}
								/>
								<Route path="/" element={<HomePage />} />
								<Route path="*" element={<Navigate to="/" />} />
							</Routes>
						</Suspense>
					</CSSTransition>
				</TransitionGroup>
				<CookiesPopup />
			</ThemeProvider>
		</I18nextProvider>
	);
}

export default AppContainer;
