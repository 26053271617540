import { socialMedia } from "server";

const contact: oneContactElement[] = [
  {
    title: {
      pl: "gdzie nas znajdziesz?",
      en: "English version",
    },
    socialMedia: [socialMedia.facebook, socialMedia.instagram],
  },
  {
    title: {
      pl: "nasze dane",
      en: "English version",
    },
    paragraphs: [
      "fundacja po prostu da się",
      "ul. Waleriana Łukasińskiego 22/7",
      "41-506 Chorzów",
      "NIP: 6272766021",
    ],
  },

  {
    title: {
      pl: "skontaktuj się z nami",
      en: "English version",
    },
    paragraphs: ["email: kontakt@poprostudasie.org", "tel. +48 663 196 076"],
  },
  {
    title: {
      pl: "tutaj możesz nas wspomóc",
      en: "English version",
    },
    paragraphs: [
      "Santander Bank Polska SA",
      "02 1090 2688 0000 0001 4416 6731",
      "KRS 0000338803",
    ],
  },
];

export type oneContactElement = {
  title: {
    pl: string;
    en: string;
  };
  paragraphs?: string[];
  socialMedia?: string[];
};

export default contact;
