const donationStatus: donationStatusI = {
   success: {
      title: "Gratulacje!",
      paragraphs: [
         "Właśnie został(e/a)ś mecenas(ką/em) naszych działań!",
         "Dziękujemy za twoją wpłatę - dzięki niej możemy działać na rzecz włączania w kulturę i tworzenia bezpiecznej, dostępnej i otwartej na różnorodność przestrzeni twórczej.",
         "Razem z Tobą zmieniamy świat na lepsze.",
         "Jesteś #poprostu super :)",
      ],
   },
   rejected: {
      title: "Niestety!",
      paragraphs: [
         "Coś poszło nie tak i płatność nie mogła być zrealizowana.",
         "Spróbuj ponownie, klikając w przycisk poniżej.",
         "Razem z Tobą zmieniamy świat na lepsze.",
      ],
   },
};
interface statusItem {
   title: string;
   paragraphs: string[];
}
interface donationStatusI {
   success: statusItem;
   rejected: statusItem;
}

export default donationStatus;
