import React, { useEffect, useRef } from "react";

const useOutsideClicker: useOutsideClickerType = (ref, callback) => {
   useEffect(() => {
      if (!callback) return;
      function handleClickOutside(event: any) {
         if (ref.current && !ref.current.contains(event.target) && callback) {
            callback();
         }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [ref, callback]);
};

function OutsideClicker({ children, callback }: outsideClickerProps) {
   const wrapperRef = useRef(null);
   useOutsideClicker(wrapperRef, callback);
   return <div ref={wrapperRef}>{children}</div>;
}

type outsideClickerProps = { children: React.ReactNode; callback?: () => void };
type useOutsideClickerType = (
   ref: React.RefObject<HTMLElement>,
   callback?: () => void
) => void;

export default OutsideClicker;
